<template>
  <div id='claim'>
    <pg-nav v-if="Layout.isPc" :title="$t('Claim')"></pg-nav>
    <div class="main-bg">
      <div class="reward">
        <span class="lighten-color">{{ $t('Reward') }}</span>
        <span class="warn-color ml01">0,00</span>
      </div>
      <div class="reward-list">
        <empty v-if="!Layout.isPc" />
        <div class="table-wrap" v-else>
          <pg-table :table-data="tableData" :config="tableConfig"></pg-table>
          <div class="pagination">
            <pg-pagination :page-size="pager.limit" :current-page="pager.page" :page-sizes="[10, 20, 30, 40, 50]"
              background small layout="prev, pager, next,total, sizes, jumper" :total="total"
              @current-change="handlerPageChange" @size-change="handleSizeChange">
            </pg-pagination>
          </div>
        </div>
      </div>
      <div class="reward-btns" v-if="!Layout.isPc">
        <pg-button class="btn">{{ $t('History') }}</pg-button>
        <pg-button class="btn" disabled>{{ $t('Claim_All') }}</pg-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [],
      tableConfig: [
        { label: this.$t('Time'), prop: 'created_at', align: 'center' },
        { label: this.$t('Name'), prop: 'name', align: 'center' },
        { label: this.$t('Reward'), prop: 'Reward', align: 'center' },
        { label: this.$t('Source'), prop: 'Source', align: 'center' },
        { label: this.$t('Status'), prop: 'Status', align: 'center' },
      ],
      pager: {
        page: 1,
        limit: 10
      },
      total: 0,
    }
  },
  inject: ['Layout'],
  methods: {
    handlerPageChange(page) {
      this.pager.page = page;
    },
    handleSizeChange(val) {
      this.pager.limit = val
      this.pager.page = 1;
    },
  },
}
</script>

<style lang='scss' scoped>
#claim {
  width: 100%;
  height: 100%;

  .main-bg {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .reward {
    height: 0.9rem;
    padding: 0 0.2rem;
    display: flex;
    font-size: .24rem;
    align-items: center;
    overflow: hidden;
    justify-content: flex-end;


  }

  .reward-list {
    flex: 1;
    overflow-y: scroll;

    .table-wrap {
      padding: 0 0.2rem;
      .pagination{
        transform: translateY(120%);
      }
    }
  }

  .reward-btns {
    font-size: 0;
    padding: 0.2rem;
    display: flex;
    box-shadow: 0 -0.03rem 0.1rem 0 rgba(0, 0, 0, .1);

    .btn {
      margin-right: 0.2rem;
      flex: 1;

      &:last-child {
        margin: 0;
      }
    }
  }
}

.pc-page{
  #claim {
    .nav-haeder {
      margin: 0 0 .2rem 0;
    }

    .main-bg {
      border-radius: .1rem;

      .reward-list {
        overflow: visible;
        .table-wrap {
          ::v-deep .pg-container {
            .pg-table {
              background: var(--theme-bg-color);
            }
          }
        }
      }
    }
  }
}
</style>