<template>
  <div class="tabs" :class="{ bg }">
    <div class="scroll-tabs" ref="tabWrap">
      <div class="tab-item" :class="{ active: value == index }" v-for="(item, index) in list" :key="index"
        @click="change(index)">

        <img class="tab-img" v-if="item.img" :src="item.img" alt="">
        <span class="tab-text">{{ item.label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      boxWidth: 0,
    }
  },
  props: {
    list: {
      type: Array,
      default() {
        return []
      }
    },
    value: {
      type: [String,Number],
      default: 0
    },
    bg: {
      type: Boolean,
      default: () => true
    }
  },
  watch: {
    list: {
      handler(val) {
        if (val.length) {
          this.current = 0;
          this.$nextTick(() => {
            this.boxWidth = this.$refs.tabWrap.clientWidth
          })
        }
      },
      immediate: true
    },
  },
  methods: {
    change(index) {
      let scrollBox = this.$refs.tabWrap;
      let currentEl = this.$refs.tabWrap.children[index]
      scrollBox.scrollTo({
        left: currentEl.offsetLeft - scrollBox.offsetLeft - this.boxWidth / 2 + currentEl.clientWidth / 2,
        behavior: 'smooth'
      })
      this.$emit('input', index);
      this.$emit('change', index);
    }
  },
}
</script>

<style lang='scss' scoped>
.tabs {
  padding: 0 0.2rem;
  height: 0.72rem;
  overflow: hidden;
  border-bottom: .01rem solid var(--theme-color-line);

  &.bg {

    background: var(--theme-main-bg-color);
  }

  .scroll-tabs {
    overflow-x: scroll;
    display: flex;
    align-items: center;
    font-size: 0;
    white-space: nowrap;
    padding-bottom: 0.2rem;

    .tab-item {
      display: inline-flex;
      align-items: center;
      margin-right: 0.4rem;
      height: 0.72rem;
      font-size: .24rem;
      color: var(--theme-text-color-darken);
      position: relative;
      cursor: pointer;
      user-select: none;
      transition: color .3s;

      .tab-img {
        width: 0.35rem;
        height: 0.35rem;
        margin-right: 0.1rem;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        height: 0;
        left: 0;
        right: 0;
        background: var(--theme-primary-color);
        transition: height .3s;
      }

      &.active {
        color: var(--theme-primary-color);

        &::after {
          height: 0.04rem;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.pc-page{
  .tabs{
    border-top-left-radius: 0.1rem;
    border-top-right-radius: 0.1rem;
  }
}
</style>