import i18n from "@/lang";
const addPrice = function (finallyNum, el) {
  let count = 0;
  finallyNum = Number(finallyNum);
  const step = Math.floor(finallyNum / (2000 / 30)) + 0.12;
  el.finallyNum = finallyNum;

  const timer = setInterval(() => {
    if (count < finallyNum) {
      count += step;
      if (count > finallyNum) {
        count = finallyNum;
      }
      el.innerText = count.toFixed(2);
    } else {
      clearInterval(timer);
    }
  }, 30);
};

export default {
  bind(el, binding) {
    el.innerText = "0.00";
    if (binding.value) {
      addPrice(binding.value, el);
    }
  },
  update(el, binding) {
    if(binding.value == 0){
      el.innerText = '0.00';
    }
    if (el.finallyNum != binding.value) {
      addPrice(binding.value, el);
    }
  },
};
