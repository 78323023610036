<template>
  <svg v-if="type === 'eye-close'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false">
    <use xlink:href="#comm_icon_hide--svgSprite:all"></use>
  </svg>
  <svg v-else-if="type === 'eye'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false">
    <use xlink:href="#comm_icon_show--svgSprite:all"></use>
  </svg>
  <svg v-else-if="type === 'play'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false">
    <use xlink:href="#comm_nav_bf--svgSprite:all"></use>
    <linearGradient id="id-3dff9704-a1a5-49ff-bbc6-190984308884" x1="0.5" x2="0.5" y2="1"></linearGradient>
  </svg>
  <svg v-else-if="type === 'last'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false">
    <use xlink:href="#comm_nav_sys--svgSprite:all"></use>
    <linearGradient id="id-33bd45f0-465d-456c-ac03-5ff4e7b391fb" x1="0.5" x2="0.5" y2="1"></linearGradient>
  </svg>
  <svg v-else-if="type === 'next'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false">
    <use xlink:href="#comm_nav_xys--svgSprite:all"></use>
    <linearGradient id="id-a71e6035-9457-4a5d-aabb-99586fa091b6" x1="0.5" x2="0.5" y2="1"></linearGradient>
  </svg>
  <svg v-else-if="type === 'listLoop'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false">
    <use xlink:href="#comm_icon_xhbf--svgSprite:all"></use>
    <linearGradient id="id-4540b32f-8044-4cb9-87d7-eb13d1ded51e" x1="0.5" x2="0.5" y2="1"></linearGradient>
  </svg>
  <svg v-else-if="type === 'singleLoop'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false">
    <use xlink:href="#comm_icon_dqxh--svgSprite:all"></use>
    <linearGradient id="id-4540b32f-8044-4cb9-87d7-eb13d1ded51e" x1="0.5" x2="0.5" y2="1"></linearGradient>
  </svg>
  <svg v-else-if="type === 'random'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false">
    <use xlink:href="#comm_icon_sj--svgSprite:all"></use>
    <linearGradient id="id-4540b32f-8044-4cb9-87d7-eb13d1ded51e" x1="0.5" x2="0.5" y2="1"></linearGradient>
  </svg>
  <svg v-else-if="type === 'player'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false">
    <use xlink:href="#comm_icon_qk--svgSprite:all"></use>
    <linearGradient id="id-edb6d3e6-da10-49b7-b846-57952f133621" x1="0.5" x2="0.5" y2="1"></linearGradient>
  </svg>
  <svg v-else-if="type === 'lock'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false">
    <use xlink:href="#input_icon_mm--svgSprite:all"></use>
  </svg>
  <svg v-else-if="type === 'user'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false">
    <use xlink:href="#input_icon_zh--svgSprite:all"></use>
  </svg>
  <svg v-else-if="type === 'slider'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false">
    <use xlink:href="#btn_zcl_arrow--svgSprite:all"></use>
  </svg>
  <svg  v-else-if="type === 'slider1'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" viewBox="0 0 14 14">
    <path id="slider1" data-name="slider1" d="M1.918,10.535a1,1,0,0,1,0-2h12a1,1,0,1,1,0,2Zm0-6a1,1,0,0,1,0-2h12a1,1,0,1,1,0,2Zm0-6a1,1,0,0,1-1-1,1,1,0,0,1,1-1h12a1,1,0,0,1,1,1,1,1,0,0,1-1,1Z" transform="translate(14.918 10.535) rotate(180)"></path>
  </svg>
  <svg v-else-if="type === 'agent-arrow'"  width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#img_h5_jt_wxjc_1--svgSprite:all"></use><linearGradient id="id-a202f5f9-3d4c-46a0-b4b5-e72ae90f747f" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'search'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false">
    <use xlink:href="#comm_icon_ss--svgSprite:all"></use>
  </svg>
  <svg v-else-if="type === 'link-third'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#input_icon_dsfbd--svgSprite:all"></use><linearGradient id="id-b43af0ee-9f31-44c5-9e20-610fc36b1fb6" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'safe-fill'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#input_icon_mb--svgSprite:all"></use><linearGradient id="id-d74b70c7-6d9a-4ae0-a82a-a8ef9918228a" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'key'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#input_icon_yz--svgSprite:all"></use><linearGradient id="id-c00f66b6-6763-4dc6-a43a-1c86e561258e" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'google'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_wd_ggyz_b--svgSprite:all"></use><linearGradient id="id-052b7af5-5322-4b92-a341-30d7e2a03d1f" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'email'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#input_icon_yj--svgSprite:all"></use><linearGradient id="id-362f2677-8d72-4f28-ab60-c608c08e78aa" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'phone'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#input_icon_sj--svgSprite:all"></use><linearGradient id="id-81201937-1b3e-42bd-80fd-5c8ca9c5c071" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'card'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#input_icon_zsxm--svgSprite:all"></use><linearGradient id="id-d9bbf209-e5f0-46b2-a30f-54eb589e567b" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'db-arrow'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#img_tghdjt--svgSprite:all"></use><linearGradient id="id-c4c2a8a9-5838-4e58-87f8-217ca2e127f1" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'share'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_tg_share--svgSprite:all"></use><linearGradient id="id-f52abacd-8867-4d42-bd7c-3379d43b6771" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'agen-arrow_2'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#img_h5_jt_wxjc_2--svgSprite:all"></use><linearGradient id="id-66a752c1-fb6f-46e5-bdbb-c3be63b05430" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'returnTop'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false">
    <use xlink:href="#comm_icon_fhdb--svgSprite:all"></use>
    <linearGradient id="id-0dc002e2-5fc6-4922-9371-953006d80ba1" x1="0.5" x2="0.5" y2="1"></linearGradient>
  </svg>
  <svg v-else-if="type === 'loginout'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"
    color="#F96959">
    <use xlink:href="#icon_grzx_tc--svgSprite:all"></use>
    <linearGradient id="id-0d3a10f5-8e56-423e-8ea1-c2b0399dcc2e" x1="0.5" x2="0.5" y2="1"></linearGradient>
  </svg>
  <svg v-else-if="type === 'copy'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false">
    <use xlink:href="#comm_icon_copy--svgSprite:all"></use>
    <linearGradient id="id-f122ecc3-f205-4379-a896-c89d2f3f2f9b" x1="0.5" x2="0.5" y2="1"></linearGradient>
  </svg>
  <svg v-else-if="type === 'refresh'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#comm_icon_sx--svgSprite:all"></use></svg>
  <svg v-else-if="type === 'close'" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 28 28"
    data-icon="close-circle" fill="currentColor" aria-hidden="true" focusable="false" class="">
    <path id="close_fill"
      d="M3323,1936a14,14,0,1,1,9.9-4.1A13.907,13.907,0,0,1,3323,1936Zm0-12.351h0l4.536,4.537a1.167,1.167,0,1,0,1.65-1.65l-4.537-4.537,4.537-4.537a1.167,1.167,0,1,0-1.65-1.65l-4.536,4.536-4.538-4.536a1.167,1.167,0,1,0-1.65,1.65l4.538,4.537-4.538,4.537a1.167,1.167,0,0,0,1.65,1.65l4.537-4.537Z"
      transform="translate(-3309 -1908)"></path>
  </svg>
  <svg v-else-if="type === 'arrow-line-left'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false">
    <use xlink:href="#comm_icon_fh--svgSprite:all"></use>
    <linearGradient id="id-e9fb05dd-54be-4127-9c09-5ef883c941b3" x1="0.5" x2="0.5" y2="1"></linearGradient>
  </svg>
  <svg v-else-if="type === 'close-line'" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16" data-icon="close"
    fill="currentColor" aria-hidden="true" focusable="false" class="">
    <g id="comm_icon_x" transform="translate(-1209.5 -160.5)">
      <path id="Line_14" data-name="Line 14"
        d="M14,15a1,1,0,0,1-.707-.293l-14-14a1,1,0,0,1,0-1.414,1,1,0,0,1,1.414,0l14,14A1,1,0,0,1,14,15Z"
        transform="translate(1210.5 161.5)"></path>
      <path id="Line_15" data-name="Line 15"
        d="M0,15a1,1,0,0,1-.707-.293,1,1,0,0,1,0-1.414l14-14a1,1,0,0,1,1.414,0,1,1,0,0,1,0,1.414l-14,14A1,1,0,0,1,0,15Z"
        transform="translate(1210.5 161.5)"></path>
    </g>
  </svg>
  <svg  v-else-if="type === 'withdrawal'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" color="#FF4A4A"><use xlink:href="#icon_grzx_txgl--svgSprite:all"></use><linearGradient id="id-6d32ef6f-aac9-4a9b-aae6-1af613c0da02" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'main_wallet'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" color="#F0C059"><use xlink:href="#icon_wd_zxqb2--svgSprite:all"></use><linearGradient id="id-e6910645-7d95-4dea-ab49-c1853587a185" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'message'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" color="#F0C059"><use xlink:href="#icon_grzx_xxzx2--svgSprite:all"></use><linearGradient id="id-6bcc0d45-ed13-42b5-a5de-9bea6c5a7696" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'report'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" color="#F0C059"><use xlink:href="#icon_grzx_grbb--svgSprite:all"></use><linearGradient id="id-c0d6651b-c028-49eb-b09e-1ac8c03ae33d" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'statement'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" color="#04BE02"><use xlink:href="#icon_grzx_tzjl--svgSprite:all"></use><linearGradient id="id-cedbe64d-7025-453e-bc0e-fad27db73e50" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'Bet_Records'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" color="#FFAA09"><use xlink:href="#icon_grzx_zhmx--svgSprite:all"></use><linearGradient id="id-709eac77-a8fd-49e9-b161-cfadabe3a474" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'pix'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#pix_icon--svgSprite:all"></use><linearGradient id="id-71796e6e-70dc-4585-ba7e-04fefa97537b" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'loading'" viewBox="0 0 1024 1024" data-icon="loading" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class="anticon-spin"><path d="M988 548c-19.9 0-36-16.1-36-36 0-59.4-11.6-117-34.6-171.3a440.45 440.45 0 0 0-94.3-139.9 437.71 437.71 0 0 0-139.9-94.3C629 83.6 571.4 72 512 72c-19.9 0-36-16.1-36-36s16.1-36 36-36c69.1 0 136.2 13.5 199.3 40.3C772.3 66 827 103 874 150c47 47 83.9 101.8 109.7 162.7 26.7 63.1 40.2 130.2 40.2 199.3.1 19.9-16 36-35.9 36z"></path></svg>
  <svg v-else-if="type === 'claim'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#comm_img_ylq--svgSprite:all"></use><linearGradient id="id-8f1f3abf-291d-48e5-b255-489de0f9174d" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'records'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_sy_zc_tzjl--svgSprite:all"></use><linearGradient id="id-df5b00a0-60ab-4d15-9ae0-48c09f044b75" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'man'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#img_szxb1--svgSprite:all"></use><linearGradient id="id-494bdcd8-027d-4521-8137-5c5f8a561703" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'woman'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#img_szxb2--svgSprite:all"></use><linearGradient id="id-c9c5bb1d-f4f4-4842-beae-d3b2498be516" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'success'" viewBox="64 64 896 896" data-icon="check-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 0 1-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z"></path></svg>
  <svg v-else-if="type === 'bank-card'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#img_tx_tjzh--svgSprite:all"></use><linearGradient id="id-811156b5-e4a8-46c3-978a-c7d5817e7e08" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'log-out'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_game_exit--svgSprite:all"></use><linearGradient id="id-5862a50e-4cac-4f3f-a4b7-c8a03afda7bf" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'deposite'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_game_cz--svgSprite:all"></use><linearGradient id="id-8c68b186-25dc-463c-bf55-97375e9c32a7" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'avatar-border'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#img_txd--svgSprite:all"></use><linearGradient id="id-e5f5c929-3660-4076-b147-83a717168381" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'tip'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#comm_icon_wh--svgSprite:all"></use><linearGradient id="id-a967fc66-299a-4f95-8a3d-c1b4bf4fe25e" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'edit'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" color="#F0C059"><use xlink:href="#icon_grzx_yjfk--svgSprite:all"></use><linearGradient id="id-be1a3c50-72d6-4f5a-9f1a-3b86281127fc" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'fqa-line'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" color="#04BE02"><use xlink:href="#icon_grzx_cjwt--svgSprite:all"></use><linearGradient id="id-64cf8773-6000-48d5-9d7a-d8192794b99e" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'safe'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" color="#04BE02"><use xlink:href="#icon_grzx_aqzx--svgSprite:all"></use><linearGradient id="id-f8546231-bc24-46b0-bb31-a183e215b83c" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'profile'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" color="#F0C059"><use xlink:href="#icon_grzx_sz--svgSprite:all"></use><linearGradient id="id-f7570153-93f9-487f-a061-622081a7102c" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'agent'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" color="#ffaa09"><use xlink:href="#icon_grzx_tg--svgSprite:all"></use><linearGradient id="id-55e686ed-f25d-4135-ba2b-d70262d29f64" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'withdraw-manage'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" color="#D1AE52"><use xlink:href="#icon_wd_txgl--svgSprite:all"></use><linearGradient id="id-80a54959-26e5-4259-83fd-e3d6aefaddb2" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'order-search'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" color="#D1AE52"><use xlink:href="#icon_wd_grbb--svgSprite:all"></use><linearGradient id="id-92f29164-e1ed-476f-b3a3-b093e9ccf0fd" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'game'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" color="#D1AE52"><use xlink:href="#icon_wd_tzjl--svgSprite:all"></use><linearGradient id="id-aaad6013-7c48-48b1-ae2d-9a416c8994da" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'account'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" ><use xlink:href="#icon_wd_zhmx--svgSprite:all"></use><linearGradient id="id-cb734690-ca7c-4827-ad4a-873874012e52" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'interest'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_wd_yeb1--svgSprite:all"></use><linearGradient id="id-bb404de3-db02-4a4f-b6fe-3173e6d8e2c0" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'deposit-fill'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_wd_cz1--svgSprite:all"></use><linearGradient id="id-6135ef81-a462-4c38-985c-d5744e607d7e" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'withdraw'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_wd_tx1--svgSprite:all"></use><linearGradient id="id-bd775473-fec5-474d-a896-aba147072fdc" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'wallet'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_wd_zxqb1--svgSprite:all"></use><linearGradient id="id-ca4561cf-7876-45e1-99b7-67afe073179f" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'message2'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_grzx_xx--svgSprite:all"></use><linearGradient id="id-101a918c-0b47-4eec-a5e9-dbd7facba367" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'sport'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_dtfl_ty_0--svgSprite:all"></use><linearGradient id="id-6808d770-d079-48fc-b5a3-0fc85e526502" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'live'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_dtfl_zr_0--svgSprite:all"></use><linearGradient id="id-60b3f102-3921-412c-9758-50854949bf90" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'cards'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_dtfl_qp_0--svgSprite:all"></use><linearGradient id="id-d257ff6c-94b6-4b93-a6fe-287b4c2e82b9" x1="0.5" x2="0.5" y2="1"><stop offset="0" stop-color="#C7C7C7"></stop><stop offset="1" stop-color="#C7C7C7"></stop></linearGradient></svg>
  <svg v-else-if="type === 'sevent'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_dtfl_dz_0--svgSprite:all"></use><linearGradient id="id-ab08f8be-45e1-481d-a6bb-54ce06ff1e93" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'fish'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_dtfl_by_0--svgSprite:all"></use><linearGradient id="id-6f0868fd-0026-41cb-8514-ef36573ea7d4" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'block'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_dtfl_qkl_0--svgSprite:all"></use><linearGradient id="id-52f7ef8f-cc9e-499e-b731-2e62e2bc6474" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'menu'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_dtfl_zh_0--svgSprite:all"></use><linearGradient id="id-2b3fb604-17aa-455f-bf0c-d7a6dd98611c" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'arrow-right-line'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#img_scroll_jt--svgSprite:all"></use><linearGradient id="id-c9b0d5aa-d372-4bfd-a5bc-d1fd0a21bef8" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'arrow-down-line'" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 35.969 21.998" data-icon="down" fill="currentColor" aria-hidden="true" focusable="false" class=""><path id="comm_icon_fh" d="M2209.28,137.564l-17.743-15.773a2,2,0,0,1-.271-3.058,2.037,2.037,0,0,1,.274-.23l17.74-15.77a1.992,1.992,0,0,1,2.817,2.816l-16.422,14.6,16.422,14.6a1.992,1.992,0,1,1-2.817,2.817Z" transform="translate(-102.181 2212.68) rotate(-90)"></path></svg>
  <svg v-else-if="type === 'true-bg'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#comm_img_corner--svgSprite:all"></use><linearGradient id="id-e1e096bc-0727-4f43-b4c8-8795f53ffd3f" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'true'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#comm_icon_gou--svgSprite:all"></use><linearGradient id="id-2b18ffd6-c617-4acf-bda4-54d9404a6042" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'support'"  width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_sy_zc_kf--svgSprite:all"></use><linearGradient id="id-3b664a47-175a-40ed-b09e-5dd3d0e5ef61" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'info'" viewBox="64 64 896 896" data-icon="exclamation-circle" width="1em" height="1em" fill="" aria-hidden="true" focusable="false" class=""><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm-32 232c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v272c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V296zm32 440a48.01 48.01 0 0 1 0-96 48.01 48.01 0 0 1 0 96z"></path></svg>
  <svg v-else-if="type === 'online'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" color="#F4E7CB"><use xlink:href="#icon_cz_zxcz1--svgSprite:all"></use><linearGradient id="id-b15da571-5574-4302-b730-5c6f25744fb6" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'download'"  width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_sy_zc_xz--svgSprite:all"></use><linearGradient id="id-ceb320c4-1e10-4cdc-91f2-6ec1b6600748" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'lang'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#comm_icon_language--svgSprite:all"></use><linearGradient id="id-77bb4243-c59f-49aa-9873-cd60575f25bb" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'fqa'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_sy_zc_cjwt--svgSprite:all"></use><linearGradient id="id-50e07c03-38bf-43f1-956d-115f1df5c1fd" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'arrow-right'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_play--svgSprite:all"></use><linearGradient id="id-d7fd069d-0eea-4b3e-a353-f396aaaf8c1a" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'arrow-down'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#comm_icon_sort--svgSprite:all"></use><linearGradient id="id-e9922765-5c2d-4af5-ad63-66f7cd222754" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'fullscreen'"  width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#comm_icon_qp--svgSprite:all"></use><linearGradient id="id-6099b672-f169-4232-ba55-624d2b2b00c7" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>

<!--  <svg  v-else-if="type === 'popular'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_dtfl_rm_0&#45;&#45;svgSprite:all"></use><linearGradient id="id-50bc8d8b-101d-41e3-80bf-3055f81c4d19" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>-->
  <svg v-else-if="type === 'popular'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" viewBox="0 0 100 76">
    <g id="icon_dtfl_rm_0" transform="translate(8077 -2686)">
      <rect id="Rectangle_3253" data-name="Rectangle 3253" width="100" height="76" transform="translate(-8077 2686)" opacity="0"></rect>
      <path id="icon_sy_zc_rm" d="M26.68,58.695c-8.547,0-15.192-2.912-16.007-10.672S14.941,37.35,14.941,37.35s2.153,7.244,8.537,6.409c12.685-1.668,8.537-28.813,8.537-28.813C55.7,42.119,36.806,58.695,26.68,58.695ZM21.343,38.418s-1.734-6.437-5.335-6.409c-3.723.034-7.9,4.258-9.6,10.678s5.335,14.94,5.335,14.94A23,23,0,0,1,0,37.35c0-11.437,9.012-17.223,14.94-23.478A12.649,12.649,0,0,0,17.075,0C29.656,10.182,30.423,23.289,27.748,30.948a12.685,12.685,0,0,1-6.4,7.47Z" transform="translate(-8049 2695)" fill-rule="evenodd"></path>
    </g>
  </svg>
<!--  <svg v-else-if="type === 'slots'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_dtfl_dz_0&#45;&#45;svgSprite:all"></use><linearGradient id="id-1ac25644-1e55-43dc-9a66-f2dc28502a3c" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>-->
  <svg v-else-if="type === 'slots'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" viewBox="0 0 100 76">
    <g id="icon_dtfl_dz_0" transform="translate(8643 -2686)">
      <rect id="Rectangle_3253" data-name="Rectangle 3253" width="100" height="76" transform="translate(-8643 2686)" opacity="0"></rect>
      <path id="icon_sy_zc_dz" d="M52.216,42.749H45.807V47.94a3.054,3.054,0,0,1-3.054,3.054H12.216A3.054,3.054,0,0,1,9.162,47.94V42.749H3.054A3.054,3.054,0,0,1,0,39.7V11.908A3.054,3.054,0,0,1,3.054,8.856H9.16V3.048A3.054,3.054,0,0,1,12.216,0H42.749A3.054,3.054,0,0,1,45.8,3.054V8.862h6.409a3.054,3.054,0,0,1,3.062,3.046V39.7a3.054,3.054,0,0,1-3.058,3.054ZM17.548,13.184a1.222,1.222,0,0,0-1.222-1.222H4.276a1.222,1.222,0,0,0-1.222,1.222V38.139a1.222,1.222,0,0,0,1.222,1.222H16.328a1.222,1.222,0,0,0,1.222-1.222Zm17.52,0a1.222,1.222,0,0,0-1.222-1.222H21.8a1.222,1.222,0,0,0-1.222,1.222V38.139A1.222,1.222,0,0,0,21.8,39.361h12.05a1.222,1.222,0,0,0,1.222-1.222Zm17.147,0a1.222,1.222,0,0,0-1.222-1.222H38.942a1.222,1.222,0,0,0-1.222,1.222V38.139a1.222,1.222,0,0,0,1.222,1.222H50.994a1.222,1.222,0,0,0,1.222-1.222ZM41.978,31.442c0-3.721,3.9-9.116,3.9-9.116H43.368v1.382H40.306V19.849h9.467v2.2a21.487,21.487,0,0,0-3.619,9.393Zm-18.3,1.222c0-4.5,4.652-11.037,4.652-11.037H25.336V23.3H21.681V18.625h11.3V21.3a26.251,26.251,0,0,0-4.32,11.371ZM6.861,31.442c0-3.721,3.9-9.116,3.9-9.116H8.253v1.382H5.191V19.849h9.467v2.2a21.487,21.487,0,0,0-3.621,9.391Z" transform="translate(-8621 2699)" fill-rule="evenodd"></path>
    </g>
  </svg>
  <svg v-else-if="type === 'pescaria'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_dtfl_by_0--svgSprite:all"></use><linearGradient id="id-e4889443-3ca5-472a-88bc-db9ad4c4bd4f" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
<!--  <svg v-else-if="type === 'blockchain'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_dtfl_qkl_0&#45;&#45;svgSprite:all"></use><linearGradient id="id-0f71e570-e632-4bb8-8a4c-d4557562e2ae" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>-->
  <svg v-else-if="type === 'blockchain'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" viewBox="0 0 100 76">
    <g id="icon_dtfl_qkl_0" transform="translate(8303 -2686)">
      <rect id="Rectangle_3253" data-name="Rectangle 3253" width="100" height="76" transform="translate(-8303 2686)" opacity="0"></rect>
      <path id="icon_sy_zc_qkl" d="M27.793,57.561,19.9,49.666a1.762,1.762,0,0,1,0-2.493L20.974,46.1l-9-9L10.9,38.177a1.762,1.762,0,0,1-2.493,0L.517,30.284a1.762,1.762,0,0,1,0-2.493L8.411,19.9a1.762,1.762,0,0,1,2.493,0l.983.983,9-9L19.9,10.9a1.762,1.762,0,0,1,0-2.493L27.793.516a1.762,1.762,0,0,1,2.491,0l7.893,7.895a1.762,1.762,0,0,1,0,2.493L37.1,11.978l9,9L47.177,19.9a1.762,1.762,0,0,1,2.493,0L57.563,27.8a1.762,1.762,0,0,1,0,2.493l-7.9,7.887a1.762,1.762,0,0,1-2.493,0l-1.166-1.166-9,9,1.166,1.166a1.762,1.762,0,0,1,0,2.493l-7.893,7.893a1.762,1.762,0,0,1-2.491,0ZM22.63,44.443l5.157-5.157a1.762,1.762,0,0,1,2.491,0l5.067,5.067,9-9-5.067-5.067a1.762,1.762,0,0,1,0-2.493l5.163-5.163-8.994-8.994-5.163,5.157a1.762,1.762,0,0,1-2.491,0l-5.249-5.247-9,9,5.249,5.247a1.762,1.762,0,0,1,0,2.493l-5.157,5.163Zm5.157-6.266L19.9,30.284a1.762,1.762,0,0,1,0-2.493L27.793,19.9a1.762,1.762,0,0,1,2.491,0l7.893,7.893a1.762,1.762,0,0,1,0,2.493l-7.893,7.893a1.762,1.762,0,0,1-2.491,0Z" transform="translate(-8282 2695)"></path>
    </g>

  </svg>
  <svg v-else-if="type === 'cartas'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_dtfl_qp_0--svgSprite:all"></use><linearGradient id="id-70222e8d-4753-4f46-b931-e91d9b368b7d" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'cassino'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_dtfl_zr_0--svgSprite:all"></use><linearGradient id="id-480de506-201c-400e-8ad0-b27648cac069" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'esporte'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_dtfl_ty_0--svgSprite:all"></use><linearGradient id="id-a4095516-a60d-444d-9a5a-9ad1ece2ce74" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'clube'"  width="1em" height="1em" fill="currentColor"  xmlns="http://www.w3.org/2000/svg" viewBox="-0.03500000014901161 0.2809999883174896 42.220001220703125 40.00403594970703"><g id="icon_grzx_qhms--svgSprite:all_icon_grzx_qhms" transform="translate(0.035 -0.281)"><circle id="icon_grzx_qhms--svgSprite:all_Ellipse_3" data-name="Ellipse 3" cx="3" cy="3" r="3" transform="translate(-0.035 5.318)"></circle><circle id="icon_grzx_qhms--svgSprite:all_Ellipse_4" data-name="Ellipse 4" cx="3" cy="3" r="3" transform="translate(18.075 0.281)"></circle><circle id="icon_grzx_qhms--svgSprite:all_Ellipse_5" data-name="Ellipse 5" cx="3" cy="3" r="3" transform="translate(36.185 5.318)"></circle><path id="icon_grzx_qhms--svgSprite:all_Path_4" data-name="Path 4" d="M1259.8,1162.031H1234.28a1.569,1.569,0,0,1-1.542-1.264l-3.682-18.864a1.558,1.558,0,0,1,.72-1.628,1.578,1.578,0,0,1,1.786.1l6.755,5.222,7.479-9.613a1.574,1.574,0,0,1,1.243-.606h.014a1.573,1.573,0,0,1,1.245.628c2.385,3.18,5.75,7.656,7.3,9.7,1.509-1.092,4.381-3.291,6.971-5.323a1.578,1.578,0,0,1,1.792-.106,1.558,1.558,0,0,1,.722,1.634l-3.745,18.864A1.568,1.568,0,0,1,1259.8,1162.031Zm-24.222-3.122h22.931l2.679-13.494-.681.522c-1.185.906-2.149,1.629-2.864,2.149-1.413,1.028-1.8,1.268-2.4,1.268h0a1.583,1.583,0,0,1-1.124-.462c-.12-.119-.2-.2-4.313-5.666-1.015-1.349-2.03-2.7-2.787-3.709l-7.177,9.226a1.577,1.577,0,0,1-2.207.277l-4.695-3.629Z" transform="translate(-1225.756 -1127.428)"></path><path id="icon_grzx_qhms--svgSprite:all_Path_5" data-name="Path 5" d="M1260.5,1180.6h-24.845a1.562,1.562,0,0,1,0-3.123H1260.5a1.562,1.562,0,0,1,0,3.123Z" transform="translate(-1226.677 -1140.315)"></path></g></svg>
  <svg v-else-if="type === 'amigos'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_dtfl_sc_0--svgSprite:all"></use><linearGradient id="id-bacf376f-23f3-4d5e-9e04-99e74a203736" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'jogos'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_dtfl_zj_0--svgSprite:all"></use><linearGradient id="id-3dfa02e0-c924-4a0b-abbb-eee0aa6bd2f7" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'caret-up'" viewBox="0 0 1024 1024" data-icon="caret-up" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"></path></svg>
  <svg v-else-if="type === 'caret-down'" viewBox="0 0 1024 1024" data-icon="caret-down" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path></svg>
  <svg v-else-if="type === 'esport'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_dtfl_dianjing_0--svgSprite:all"></use><linearGradient id="id-8322c811-9288-4241-b576-ddcc83883a8b" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'luta'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_dtfl_douji_0--svgSprite:all"></use><linearGradient id="id-942026f6-b46a-46d0-8e5a-2300dfce4180" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'loteria'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_dtfl_cp_0--svgSprite:all"></use><linearGradient id="id-48985f49-a09e-4c7e-82d5-f97e2cfdfd65" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'game-loading'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" ><use xlink:href="#img_mryx_card--svgSprite:all"></use><linearGradient id="id-55ebb9fd-1527-4146-9db1-0fb0f4dacf10" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'more'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#comm_icon_jzgd--svgSprite:all"></use><linearGradient id="id-d0ec5ea3-9fcc-4f6e-911b-b874a2a4d2d1" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'exclamation'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#img_hd_ts--svgSprite:all"></use><linearGradient id="id-92930c9e-ebfa-4887-80b0-875e303a020f" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'randomLoop'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#comm_icon_sj--svgSprite:all"></use><linearGradient id="id-2975066c-3546-4f98-8faf-6e2f33ec9a93" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'repeatLoop'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#comm_icon_dqxh--svgSprite:all"></use><linearGradient id="id-2975066c-3546-4f98-8faf-6e2f33ec9a93" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'pause'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#comm_nav_zt--svgSprite:all"></use><linearGradient id="id-4386d93f-ac8c-4107-84fc-72bd92605863" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'music'" color="#F0C059" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_yy_yy--svgSprite:all"></use><linearGradient id="id-026dd18b-4a8b-403b-85e6-fdef1df5d5a2" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <!-- <svg v-else-if="type === 'download'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#comm_icon_xz--svgSprite:all"></use><linearGradient id="id-0772e811-05da-402f-a9f4-a002132a88a6" x1="0.5" x2="0.5" y2="1"></linearGradient></svg> -->
  <svg v-else-if="type === 'delete'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#comm_icon_del--svgSprite:all"></use><linearGradient id="id-d13a34d7-f320-4dcc-bba2-67148da59c07" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'volume'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#comm_icon_sy--svgSprite:all"></use><linearGradient id="id-a3b02e03-c8f0-4998-8927-b193ea19ab28" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'game-load-bg'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" style="    color: var(--theme-text-color-lighten);"><use xlink:href="#img_mryx_card--svgSprite:all"></use><linearGradient id="id-c7040f75-ab29-42d6-9115-aa18a264d381" x1="0.5" x2="0.5" y2="1"></linearGradient></svg>
  <svg v-else-if="type === 'line-arrow'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#comm_icon_fy_jt--svgSprite:all"></use></svg>
   <svg v-else-if="type === 'event'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_sy_zc_hd--svgSprite:all"></use></svg>
  <svg v-else-if="type === 'mission'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_sy_zc_rw--svgSprite:all"></use></svg>
  <svg v-else-if="type === 'rebate'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_sy_zc_fs--svgSprite:all"></use></svg>
  <svg v-else-if="type === 'reward'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_sy_zc_jl--svgSprite:all"></use></svg>
  <svg v-else-if="type === 'history'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_sy_zc_lqjl--svgSprite:all"></use></svg>
  <svg v-else-if="type === 'interestSlider'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_sy_zc_yeb--svgSprite:all"></use></svg>
  <svg v-else-if="type === 'vip'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_sy_zc_vip--svgSprite:all"></use></svg>
  <svg v-else-if="type === 'agentslide'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false"><use xlink:href="#icon_sy_zc_tgzq--svgSprite:all"></use></svg>
  <svg v-else-if="type === 'demo'" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" viewBox="0 0 100 76">
    <g id="icon_dtfl_sw_0" transform="translate(7851 -2686)">
      <rect id="Rectangle_3253" data-name="Rectangle 3253" width="100" height="76" transform="translate(-7851 2686)" opacity="0"></rect>
      <path id="icon_sy_zc_sw" d="M44.86,34.021h-27.3A17.309,17.309,0,0,1,0,17.01,17.309,17.309,0,0,1,17.558,0h27.3A17.311,17.311,0,0,1,62.42,17.01,17.311,17.311,0,0,1,44.86,34.021ZM49.186,17.8a3.278,3.278,0,1,0,3.381,3.276A3.332,3.332,0,0,0,49.2,17.8Zm-35.9-3.933a2.622,2.622,0,1,0,0,5.243h2.786v2.539a2.7,2.7,0,1,0,5.407,0V19.109h2.786a2.622,2.622,0,1,0,0-5.243H21.479V11.326a2.7,2.7,0,0,0-5.407,0v2.539Zm30.5-5.253A3.278,3.278,0,1,0,47.165,11.9a3.33,3.33,0,0,0-3.381-3.284Z" transform="translate(-7832 2707.001)"></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    type: {
      type: String
    }
  },
  methods: {},
}
</script>

<style lang='scss' scoped>.icon {
  height: auto;

  svg {
    transition: all .3s;
  }
}</style>