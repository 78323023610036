<template>
  <div id='maintain'>
    <div class="main-wrap">
      <img class="maintain-img" src="./maintain.png" alt="">
      <div class="main-text">
        <p>usuário respeitado:</p>
        <p>Olá!</p>
        <p>Tenho o prazer de informar que interromperemos o serviço e atualizaremos para a versão principal V3.8! O
          serviço
          será interrompido no próximo horário, portanto fique atento às novidades! Se o tempo for reduzido ou prorrogado,
          novo aviso será anunciado.</p>
        <p>Pare o serviço e inicie: {{ startTime }};
        <p> A suspensão do serviço termina: {{ endTime }} (aproximadamente 6 horas) ou aviso prévio;</p>
        Conteúdo envolvido: Todas as funções do lobby da Web, todas as funções do lobby do APP, site de download e site de
        promoção e todos os jogos.</p>
        <p>Pedimos desculpas por qualquer inconveniente causado a você!</p>
      </div>
      <div class="contact" @click="toConcat">
        <div class="icon-wrap">
          <img class="icon" src="./icon.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    time: {
      type: String,
      default: () => ""
    },
    url: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {

    }
  },
  computed: {
    startTime() {
      let time = String(this.time)
      return time.split('~')[0] || ''
    },

    endTime() {
      let time = String(this.time)
      return time.split('~')[1] || ''
    },
  },
  methods: {
    toConcat() {
      window.open(this.url)
    }
  },
}
</script>

<style lang='scss' scoped>
#maintain {
  width: 100%;
  height: 100vh;
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: .6);
  display: flex;
  align-items: center;
  justify-content: center;

  .main-wrap {
    border-radius: 10px;
    width: 90vw;
    background: #fff;
    font-size: .22rem;
    padding: 0.2rem;
    min-height: 60vh;
    max-height: 80vh;
    overflow-y: auto;
    line-height: 1.6;
    position: relative;

    .main-text{
      padding: .3rem;
    }

    .maintain-img {
      display: block;
      margin: 0 auto;
      width: 4.32rem;
      max-width: 100%;
    }

    .contact {
      position: absolute;
      width: .72rem;
      height: .78rem;
      top: 38%;
      right: 0.3rem;
      background: #fff;
      border-radius: .1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 0 10px rgba($color: #000000, $alpha: .2);

      .icon-wrap { 
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
          background-color: #1678ff;
          width: 0.463rem;
          height: 0.463rem;
          border-radius: 50%;
          padding: 0.06rem;
        }
      }
    }
  }
}

.pc-page{
  #maintain {
    .main-wrap {
      width: 45vw;
      max-width: 800px;
    }
  }
}</style>