<template>
  <div class="event-wrap">
    <div class="event-scorll-wrap">
      <div class="event-line">
        <div></div><span>Todas as atividades</span>
        <div></div>
      </div>
      <div class="event-list-scroll-wrap">
        <div class='event-list'>
          <swiper :options="swiperOption">
            <swiper-slide  class="event-item" :class="{ active: current === index }" v-for="(item, index) in list" :key="index"
                           @click="$router.replace(item.url)">
              <div class="img-wrap">
                <img class="cover-img" :src="item.cover_image" alt="">

                <div class="check-wrap">
                  <i class="icon-wrap">
                    <Icon type="true" />
                  </i>
                  <i class="icon-bg">
                    <Icon type="true-bg" class="true-bg" />
                  </i>
                </div>
              </div>

              <div class="event-title">{{ item.title }}</div>
            </swiper-slide>
          </swiper>
<!--          <div class="event-item" :class="{ active: current === index }" v-for="(item, index) in list" :key="index"-->
<!--               @click="$router.replace(item.url)">-->
<!--            <div class="img-wrap">-->
<!--              <img class="cover-img" :src="item.cover_image" alt="">-->

<!--              <div class="check-wrap">-->
<!--                <i class="icon-wrap">-->
<!--                  <Icon type="true" />-->
<!--                </i>-->
<!--                <i class="icon-bg">-->
<!--                  <Icon type="true-bg" class="true-bg" />-->
<!--                </i>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="event-title">{{ item.title }}</div>-->
<!--          </div>-->
        </div>

      </div>
    </div>
    <div class="arrow left" v-if="current>0" @click="navTo(-1)">
      <Icon type='arrow-right-line' />
    </div>
    <div class="arrow right" v-if="current<list.length" @click="navTo(1)">
      <Icon type='arrow-right-line' />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      current: -1,
      swiperOption: {
        freeMode:true,
        spaceBetween: 30,
        // effect: 'coverflow',
        // slidesPerView: 2,
        // centeredSlides: true,
        // coverflowEffect: {
        //     rotate: 0,
        //     stretch: 10,
        //     depth: 160,
        //     modifier: 2,
        //     slideShadows: true
        // },

      },
    }
  },
  watch: {
    list: {
      handler(val) {
        val.forEach((item, index) => {
          if (item.url === this.$route.path) {
            this.current = index;
          }
        })
      },
      immediate: true
    }
  },
  methods: {
    navTo(dir){
      const index = this.current+dir
      if(this.list[index])
      this.$router.replace(this.list[index].url)
    }
  },
}
</script>

<style lang='scss' scoped>
.event-wrap {
  position: relative;
  padding: 0 0.4rem 1.2rem 0.4rem;

  .arrow {
    background-color: rgba(0, 0, 0, 0.2);
    border: 0.01rem solid rgba(255, 255, 255, 0.1);
    border-radius: 0.18rem;
    top: 40%;
    color: rgb(255, 255, 255);
    cursor: pointer;
    height: 0.36rem;
    line-height: 0.36rem;
    margin: 0.18rem 0px;
    text-align: center;
    width: 0.36rem;
    position: absolute;
    display: none;
    align-items: center;
    justify-content: center;
    font-size: .14rem;
    padding-top: 0.01rem;
    box-sizing: border-box;
    z-index: 9;

    &.right {
      right: 0;
    }

    &.left {
      left: 0;
      transform: rotate(-180deg);
    }
  }
}

.event-scorll-wrap {
  padding: 0.2rem 0;
  border-top: .01rem solid var(--theme-color-line);
  overflow-y: scroll;
  position: relative;

  .event-line {
    display: none !important;
  }
  .event-list-scroll-wrap{
    width: 100%;
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
    .event-list {
      padding: 0;
      width: fit-content;
      white-space: nowrap;

      .event-item {
        //margin-right: 10px;
        display: inline-block;
        width: 1.8rem;

        &.active {
          .img-wrap {

            border: 1px solid var(--theme-primary-color);
            border-radius: .1rem;
            .check-wrap {
              display: block;
            }
          }

        }

        .img-wrap {
          display: inline-flex;
          align-items: center;
          padding: 2px;
          justify-content: center;
          position: relative;
          padding: 0.1rem;
          .cover-img {
            width: 100%;
            border-radius: 0.1rem;
            position: relative;
          }

          .check-wrap {
            bottom: -0.01rem;
            height: 0.3rem;
            line-height: .3rem;
            overflow: hidden;
            position: absolute;
            right: -0.01rem;
            width: 0.3rem;
            z-index: 1;
            display: none;

            .icon-wrap {
              display: inline-flex;
              justify-content: center;
              align-items: center;
              color: var(--theme-web_filter_gou);
              height: 0.096rem;
              position: absolute;
              right: 0.04rem;
              width: 0.125rem;
              z-index: 0;
              bottom: 0.04rem;
            }

            .icon-bg,
            .true-bg {
              height: 100%;
              color: var(--theme-active-gou-color);
              width: 100%;
            }
          }
        }

        .event-title {
          font-size: 0.16rem;
          margin-top: .1rem;
          height: 10px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: var(--theme-text-color-lighten);
        }

      }


    }
  }

}
.mobile-page{
 .event-wrap {
    padding: 0 0.4rem;
    .arrow {
      display: inline-block !important;
    }
  }
  .event-scorll-wrap {
    border: none;

    .event-line {
      -ms-flex-align: center;
      align-items: center;
      color: var(--theme-text-color);
      display: -ms-flexbox;
      display: flex !important;
      ;
      font-size: .24rem;
      line-height: .31rem;
      padding-bottom: .2rem;

      div {
        border-top: 0.01rem solid var(--theme-color-line);
        flex-grow: 1;
        height: 0;
      }

      span {
        padding: 0 0.22rem;
      }
    }

    .event-list {
      .event-item {
        height: 1.44rem !important;
        margin-bottom: 0.2rem;
        width: 2.95rem !important;
      }
    }
  }
}
</style>