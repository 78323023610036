<template>
  <div class="slider-wrap">
    <div class="slider-body" v-if="gameList.length > 0">
      <el-collapse v-model="activeName">
        <el-collapse-item name="casino">
          <template slot="title">
            {{ $t('Casino') }}
          </template>
          <div class="game-menu">
            <div class="slider-item" :class="{ active: currentTab == index }" v-for="(item, index) in gameList" :key="index"
                 @click="menuClick(index)">
              <Icon class="game-icon" :type="item.tag_code"></Icon>
              <span class="title" v-autoSize="item.title">{{ item.title }}</span>
            </div>
            <div class="slider-item" @click="toPage('/report?current=1')">
              <Icon class="game-icon" style="font-size: .33rem;margin-left: .15rem" type="records"></Icon>
              <span class="title">{{ $t('Bet_Records') }}</span>
            </div>
          </div>
          <div class="rr3HNBdmmZ543biTVFqP">
            <div class="music-Content">
              <div class="player-header">
                <div class="H7iTRWC9oJQP28_fgyE0" @click="changePlayIndex(-1)">
                  <Icon type="last" />
                </div>
                <div class="H7iTRWC9oJQP28_fgyE0"  @click="playOrPause">
                  <Icon :type="isPause ? 'play' : 'pause'" />
                </div>
                <div class="H7iTRWC9oJQP28_fgyE0"  @click="changePlayIndex(1)">
                  <Icon type="next" />
                </div>
                <div class="H7iTRWC9oJQP28_fgyE0" @click="loopClick">
                  <Icon class="loop-icon" :type="loopList[loopIndex].icon" />
                </div>
                <div class="H7iTRWC9oJQP28_fgyE0 icon-list" @click="Layout.$refs.musicDialog.show()">
                  <Icon type="player" />
                  <div class="music-count">{{ downloadIdArr.length }}</div>
                </div>
              </div>

              <div class="sone-name">{{ currentPlayMusicInfo.name }}</div>
            </div>
          </div>
        </el-collapse-item>

        <el-collapse-item name="offer">
          <template slot="title">
            {{ $t('OfferCenter') }}
          </template>

          <div class="SxNJ58Fc">
            <div class="z83IDxYDQTBjSxNJ58Fc">

              <div class="lTyJ2d954Isz5ZsXeCjw" @click="toPage('/offers?current=0')">
                <Icon class="nR5O9QibRJ9_9Keqd1XB" type="mission" />
                <span>{{ $t('Event') }}</span>
              </div>

              <div class="lTyJ2d954Isz5ZsXeCjw" @click="$emit('showMission')">
                <Icon class="nR5O9QibRJ9_9Keqd1XB" type="mission" />
                <span>{{ $t('Mission') }}</span>
              </div>

              <div class="lTyJ2d954Isz5ZsXeCjw" @click="toPage('/offers?current=3')">
                <Icon class="nR5O9QibRJ9_9Keqd1XB" type="rebate" />
                <span>Rebate</span>
              </div>

              <div class="lTyJ2d954Isz5ZsXeCjw" @click="toPage('/offers?current=5')">
                <Icon class="nR5O9QibRJ9_9Keqd1XB" type="claim" />
                <span>{{ $t('Claim') }}</span>
              </div>
              <div class="lTyJ2d954Isz5ZsXeCjw" @click="toPage('/offers?current=6')">
                <Icon class="nR5O9QibRJ9_9Keqd1XB" type="history" />
                <span>{{ $t('History') }}</span>
              </div>

              <div class="lTyJ2d954Isz5ZsXeCjw" @click="toPage('/offers?current=4')">
                <Icon class="nR5O9QibRJ9_9Keqd1XB" type="interest" />
                <span>{{ $t('interest') }}</span>
              </div>

              <div class="lTyJ2d954Isz5ZsXeCjw" @click="toPage('/offers?current=2')">
                <Icon class="nR5O9QibRJ9_9Keqd1XB" type="vip" />
                <span>VIP</span>
              </div>

              <div class="lTyJ2d954Isz5ZsXeCjw" @click="toPage('/agent')">
                <Icon class="nR5O9QibRJ9_9Keqd1XB" type="agentslide" />
                <span class="full-span">{{ $t('Agent') }}</span>
              </div>
            </div>

          </div>
        </el-collapse-item>
        <el-collapse-item name="support">
          <template slot="title">
            {{ $t('SupportCenter') }}
          </template>

          <div class="s-menu-list">
            <drop placement="right" ref="lang" @isShow="showLang = $event">

              <div class="sm-item" :class="{ active: showLang }">
                <div class="sm-left">
              <span class="sm-icon">
                <Icon type="lang" />
              </span>
                  <span class="sm-text">{{ currentLangText }}</span>
                </div>
                <div class="sm-right">
                  <Icon type="arrow-down" />
                </div>
              </div>
              <template slot="content">
                <div class="slider-right-wrap">
                  <div class="slider-right">
                    <div class="sr-item" :class="{ active: item.value == currentLang }" v-for="(item, index) in langList"
                         :key="index" @click="changeLang(item.value)">{{ item.label }}</div>
                  </div>
                </div>
              </template>
            </drop>
            <div class="sm-item">
              <div class="sm-left">
                <div class="w-bg">
              <span class="sm-icon ">
                <Icon type="download" />
              </span>
                </div>
                <span class="sm-text">{{ $t('APP_download') }}</span>
              </div>
            </div>
            <div class="sm-item" @click="toPage('/customer')">
              <div class="sm-left">
                <div class="w-bg">
              <span class="sm-icon ">
                <Icon type="support" />
              </span>
                </div>
                <span class="sm-text">Suporte</span>
              </div>
            </div>
            <div class="sm-item" @click="toPage('/customer')">
              <div class="sm-left">
                <div class="w-bg">
              <span class="sm-icon ">
                <Icon type="fqa" />
              </span>
                </div>
                <span class="sm-text">FAQ</span>
              </div>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>

    </div>
    <div class="slider-body" v-else>
      <div class="game-menu">
        <div class="loading-item loading-animation" v-for="item of 14" :key="item"></div>
        <div class="loading-item full loading-animation"></div>
        <div class="loading-item loading-animation"></div>
        <div class="loading-item loading-animation"></div>
        <div class="loading-item loading-animation"></div>
        <div class="loading-item loading-animation"></div>
        <div class="loading-item loading-animation"></div>
        <div class="loading-item full loading-animation"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      activeName: 'casino',
      icons: {
        amigos: require('@/assets/images/category/amigos.png'),
        jogos: require('@/assets/images/category/jogos.png'),
        popular: require('@/assets/images/category/popular.png'),
        slots: require('@/assets/images/category/slots.png'),
        blockchain: require('@/assets/images/category/blockchain.png'),
        pescaria: require('@/assets/images/category/pescaria.png'),
        cartas: require('@/assets/images/category/cartas.png'),
        cassino: require('@/assets/images/category/cassino.png'),
        esporte: require('@/assets/images/category/esporte.png'),
        clube: require('@/assets/images/category/clube.png'),
        esport: require('@/assets/images/category/esport.png'),
        luta: require('@/assets/images/category/luta.png'),
        loteria: require('@/assets/images/category/loteria.png')
      },
      langList: [
        { label: 'Português', value: 'por' },
        { label: 'English', value: 'en' },
        { label: 'Español', value: 'es' }
      ],
      currentLang: localStorage.getItem('language') || 'por',
      showLang: false,
    }
  },
  inject: ['Layout'],
  computed: {
    ...mapState({
      gameList: state => state.home.gameList,
      currentTab: state => state.home.currentTab,
      isPause: state => state.music.isPause,
      downloadIdArr: state => state.music.downloadIdArr,
      loopList: state => state.music.loopList,
      loopIndex: state => state.music.loopIndex,
      currentPlayMusicInfo: state => state.music.currentPlayMusicInfo,
    }),
    currentLangText() {
      return this.langList.filter(item => item.value === this.currentLang)[0]?.label
    }
  },
  methods: {
    loopClick() {
      let tempLoopIndex = this.loopIndex + 1;
      if (tempLoopIndex == 3) {
        tempLoopIndex = 0
      }
      this.$store.commit('SET_LOOP_INDEX', tempLoopIndex);
    },
    playOrPause(){
      this.Layout.$refs.musicDialog.playOrPause();
    },
    changePlayIndex(step){
      this.Layout.$refs.musicDialog.changePlayIndex(step);
    },
    changeLang(value) {

      this.currentLang = value;
      localStorage.setItem('language', value)
      this.$i18n.locale = value;
      location.reload()
    },
    toPage(path) {
      this.$router.push(path)
      if (!this.Layout.isPc) {
        this.$store.commit('SET_SLIDER_SHOW', false)
      }
    },
    menuClick(index) {
      if (this.$route.path !== '/index') {
        this.$router.push('/index')
      }
      this.$store.commit('SET_CURRENT_TAB_ISSCROLL', false)
      this.$store.commit('SET_CURRENT_TAB', index)
    },
  },
}
</script>

<style lang='scss' scoped>
.slider-right-wrap {
  padding: 0 0.2rem;

  .slider-right {
    padding: .2rem 0;
    border-radius: 0.1rem;
    border: 0.01rem solid var(--theme-color-line);
    background-color: var(--theme-main-bg-color);
    animation: slider-from-left .3s;

    .sr-item {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: var(--theme-text-color);
      font-size: .24rem;
      height: 0.6rem;
      line-height: .6rem;
      padding: 0 0.5rem;
      cursor: pointer;

      &:hover {
        color: var(--theme-primary-color);
        background-color: var(--theme-bg-color)
      }

      &.active {
        color: var(--theme-primary-color);
      }
    }
  }

}

.slider-wrap {
  height: 100%;
}

.slider-body {
  height: 100%;
  width: 3.1rem;
  box-sizing: border-box;
  padding: 0 .15rem 1rem;
  background: var(--theme-main-bg-color);
  overflow-y: auto;
  ::v-deep .el-collapse{
    border: none;
    .el-collapse-item__header{
      justify-content: center;
      height: .8rem;
      color: var(--theme-text-color-lighten);
      border-bottom: 0;
      background: transparent;
      position: relative;
      &:after{
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        border-top: 1px solid #ebedf0;
        left: 0;
        right: 0;
        transform: scaleY(.3);
      }
      .el-collapse-item__arrow{
        margin: 0 0 0 .1rem;
        font-size: .14rem;
        color: var(--theme-alt-neutral-1);
      }
    }
    .el-collapse-item__wrap{
      background: transparent;
      border-bottom:0
    }
    .el-collapse-item__content{
      padding-bottom: .2rem;
      position: relative;
      &:after{
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        border-top: 1px solid #ebedf0;
        left: 0;
        right: 0;
        transform: scaleY(.3);
      }
    }
  }
  .game-menu {
    width: 100%;
    box-sizing: border-box;

    .loading-item {
      width: 1.3rem;
      height: 0.9rem;
      font-size: .2rem;
      margin-bottom: 0.1rem;
      border-radius: 0.14rem;

      &.full {
        width: 100%;
      }
    }

    .slider-item {
      display: flex;
      align-items: center;
      margin-bottom: 0.1rem;
      cursor: pointer;
      border-radius: .32rem;
      font-size: .24rem;
      height: .72rem;
      padding: 0 .2rem;
      color: var(--theme-alt-neutral-1);
      .game-icon{
        font-size: .6rem;
        margin-right: .1rem;
      }
      .title{
        width: 80%!important;
      }

      &.active {
        background: rgba(var(--theme-color-line-hex),.5);
        color: #fff;
      }

      .icon-wrap {
        width: 0.5rem;
        height: 0.38rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.26rem;
      }

      .slider-img {
        height: 0.38rem;
        object-fit: contain;
      }
    }
  }

  .rr3HNBdmmZ543biTVFqP {
    position: relative;

    .music-Content {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 100%;
      height: 100%;
	    padding-top: 0.08rem;
		
      .player-header {
        display: flex;
        width: 100%;
        justify-content: space-around;

        .H7iTRWC9oJQP28_fgyE0 {
          color: var(--theme-alt-neutral-2)!important;
          cursor: pointer;
          font-size: .2rem;
          text-rendering: optimizeLegibility;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          display: inline-block;
          font-style: normal;
          line-height: 0;
          text-align: center;
          text-transform: none;
          position: relative;
          &.icon-list{
            font-size: .26rem;
          }

          .music-count {
            background-color: var(--theme-main-bg-color);
            border: 0.01rem solid var(--theme-color-line);
            border-radius: 0.2rem;
            color: var(--theme-text-color);
            left: -0.32rem;
            line-height: .19rem;
            min-width: 0.2rem;
            padding: 0 0.06rem;
            position: absolute;
            text-align: center;
            top: -0.14rem;
          }
        }
      }

      .sone-name {
		    margin-top: .06rem;
        color: var(--theme-text-color);
        font-size: .18rem;
        left: 0;
        overflow: hidden;
        padding: 0 0.1rem;
        text-align: center;
        text-overflow: ellipsis;
        top: 0.3rem;
        white-space: nowrap;
        width: 90%;
      }
    }
  }


  .SxNJ58Fc {
    width: 100%;
    box-sizing: border-box;

    .z83IDxYDQTBjSxNJ58Fc {
      margin: 0.1rem 0 0.05rem;
      padding: 0.1rem 0.1rem 0;

      .lTyJ2d954Isz5ZsXeCjw {
        cursor: pointer;
        border-radius: .32rem;
        font-size: .24rem;
        height: .72rem;
        display: flex;
        align-items: center;
        padding: 0 .2rem;

        .nR5O9QibRJ9_9Keqd1XB{
          color: var(--theme-alt-neutral-1);
          font-size: .34rem;
        }
        span {
          line-height: 1.2;
          font-size: .26rem;
          margin-left: .2rem;
          color: var(--theme-alt-neutral-1);
        }

        .ac-img {
          width: 100%;
          height: 100%;
        }

        &.full {
          width: 100%;
          display: flex;

          .full-span {
            font-size: .24rem;
            left: 33%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }

  }

  .s-menu-list {
    padding: 0 .15rem;

    .sm-item {
      color: var(--theme-text-color-lighten);
      cursor: pointer;
      height: 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.04rem 0 0.04rem 0.14rem;
      font-size: .22rem;
      position: relative;



      .sm-left {
        display: flex;
        align-items: center;

        .sm-signal {
          -ms-flex-align: center;
          align-items: center;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-direction: column;
          flex-direction: column;
          gap: 0.02rem;
        }

        .sm-icon {
          color: var(--theme-text-color-lighten);
          font-size: .32rem;
          margin-left: 0.01rem;
          display: flex;
          align-items: center;
          justify-content: center;

          .sm-link {
            -ms-flex-align: end;
            align-items: flex-end;
            display: -ms-flexbox;
            display: flex;

            span:nth-of-type(1) {
              height: 0.1rem;
              margin-right: 0.04rem;
              border-radius: 0.023rem;
              display: block;
              width: 0.045rem;
              background: rgb(4, 190, 2);
            }

            span:nth-of-type(2) {
              height: 0.15rem;
              margin-right: 0.04rem;
              border-radius: 0.023rem;
              display: block;
              width: 0.045rem;
              background: rgb(4, 190, 2);
            }

            span:nth-of-type(3) {
              height: 0.2rem;
              margin-right: 0.04rem;
              border-radius: 0.023rem;
              display: block;
              width: 0.045rem;
              background: rgb(4, 190, 2);
            }
          }

          .sm-delay {
            font-size: .12rem;
            color: rgb(34, 141, 104);
          }
        }

        .w-bg {
          align-items: center;
          background: var(--theme-text-color-lighten);
          border-radius: 50%;
          display: flex;
          height: 0.32rem;
          width: 0.32rem;
          justify-content: center;
          position: relative;

          .sm-icon {
            color: var(--theme-side-menu-bg-color);
            font-size: .32rem;
            margin-left: 0.01rem;
          }
        }

        .sm-text {
          margin-top: -0.02rem;
          margin-left: 0.2rem;
        }
      }



      &:hover {
        color: var(--theme-primary-color);

        .sm-icon {
          color: var(--theme-primary-color);
        }

        .w-bg {
          background-color: var(--theme-primary-color);
        }

        .slider-right-wrap .slider-right {
          display: block;
          animation: slider-from-left .3s;
        }

      }

      &.active {
        color: var(--theme-primary-color);

        .sm-icon {
          color: var(--theme-primary-color);
        }

        .sm-right {
          transform: rotate(-90deg);
          color: var(--theme-primary-color);
        }
      }

      .sm-right {
        font-size: .14rem;
        transition: all .3s;
        margin-top: 0.05rem;
      }
    }
  }
}


.pc-page{
  .slider-body {
    width: 2.4rem;

    .game-menu {
      .loading-item {
        width: 1rem;
        height: 0.7rem;
      }

      .slider-item {
        width: 1rem;
        height: 0.7rem;

        .title {
          text-align: center;
          font-size: 12px !important;
          white-space: nowrap;
        }

        .slider-img {
          width: 0.3rem;
          height: 0.38rem;
        }
      }
    }

    .bet-record span {
      font-size: .2rem;
    }

    .SxNJ58Fc .activity .lTyJ2d954Isz5ZsXeCjw {
      height: 0.6rem;

      &.full {
        width: 100%;
        display: flex;

        .full-span {
          font-size: .18rem;
          left: 36%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

}
</style>