<template>
  <div class='pg-form'>
    <div  v-for="(item, index) in model" :key="index">
      <div class="form-item">
        <PgInput v-model="item.value" v-if="item.type === 'input'" v-bind="item.attr" v-on="item.event" @input="handlerInput(item)">
        </PgInput>
        <PgInput class="input-with-select" v-model="item.value" v-else-if="item.type === 'inputWithSelect'" v-bind="item.attr" v-on="item.event" @input="handlerInput(item)">
          <template #prefix>
            <pg-select filterable v-model="item.selectValue" v-bind="item.selectAttr" />
          </template>
        </PgInput>
        <PgInput class="input-with-select phone" v-model="item.value" v-else-if="item.type === 'inputWithSelectFlags'" v-bind="item.attr" v-on="item.event" @input="handlerInput(item)">
          <template #prefix>
            <pg-select filterable v-model="item.selectValue"  :isSlotSelection="true" :isSlot="true" :options="flagsOptions">
              <template #selection="{ data }">
                <div class="row column-c">
                  <img class="icon-flag"  style="width: 0.28rem;margin-right: 0.1rem" :src="data.flag" alt="">
                  <span>{{ data.label }}</span>
                </div>
              </template>
              <template #default="{ item }">
                <div class="row column-c">
                  <img class="icon-flag" style="width: 0.28rem;margin-right: 0.1rem" :src="item.flag" alt="">
                  <span>{{ item.country }}（{{ item.label }}）</span>
                </div>
              </template>
            </pg-select>
          </template>
        </PgInput>
        <PgInput v-model="item.value" v-else-if="item.type === 'inputSlotPrefix'" v-bind="item.attr"
          @input="handlerInput(item)">
          {{ item.value }}
          <img class="input-img" slot="prefix" :src="item.img" alt="">
        </PgInput>
        <pg-select v-model="item.value" v-else-if="item.type === 'select'" v-bind="item.attr"
          @change="handlerInput(item)"></pg-select>
        <transition name="pg-validate" @after-leave="afterLeave(item)">
          <div v-show="item.showValidate" :class="['form-tip', 'tip-' + item.validateState]">{{ item.tip }}</div>
        </transition>
        <div class="flex" v-if="item.type === 'imgCode'">
          <PgInput class="captcha" v-model="item.value"></PgInput>
          <img v-show="imageCode" class="captcha-img" :src="imageCode" alt="" @click="getImageCode">
        </div>
      </div>
      <slot :name="item.prop" :data="item"></slot>
    </div>
  </div>
</template>

<script>
import { apiImageCode } from '@/api'
export default {
  name: 'PgForm',
  data() {
    return {
      model: [],
      imageCode: '',
      key: '',
      originFormData: {},
      tempForm: {},
      flagsOptions:[
        {
          flag:require('@/assets/images/flags/Brazil.png'),
          label: '+55',
          country:'Brazil',
          value:55
        }
      ]
    }
  },
  props: {
    config: {
      type: Array,
      default() {
        return []
      }
    },
    value: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  watch: {
    config: {
      handler(val) {
        let model = []
        val.forEach(item => {
          model.push({
            ...item,
            value: this.value[item.prop] || '',
            validateState: '',
            showValidate: false,
            tip: ''
          })
        });
        this.model = model
      },
      immediate: true
    },
    value: {
      handler(val) {
        this.tempForm = val
      },
      deep: true
    }
  },
  created() {
    this.originFormData = {...this.value},
    this.tempForm = this.value
  },
  mounted() {
    if (this.config.findIndex(o => o.type == 'imgCode') !== -1) {
      this.getImageCode()
    }
  },
  methods: {
    setForm(form){
      this.model.forEach(item=>{
        item.value = form[item.prop]
      })
    },
    resetForm() {
      this.model.forEach(item => {
        item.value = this.originFormData[item.prop]
        item.validateState = '',
          item.showValidate = false,
          item.tip = ''
      })
    },
    handlerInput(item) {
      this.tempForm[item.prop] = item.value;
      this.$emit('input',this.tempForm)
      this.validateField(item)
    },
    validateField(item) {
      let isVaildate = true;
      const tip = item.validate && item.validate(item.value, this.getFormValue())
      if (tip) {
        item.validateState = 'error'
        item.tip = tip
        item.showValidate = true;
        isVaildate = false
      } else {
        item.showValidate = false;
      }
      return isVaildate
    },
    getImageCode() {
      apiImageCode().then(res => {
        this.imageCode = res.data.img
        this.key = res.data.key
      })
    },
    afterLeave(item) {
      item.tip = ''
      item.validateState = ''
    },
    getFormValue() {
      let obj = {}
      this.model.forEach(item => {
        obj[item.prop] = item.value
      })
      if (this.key) {
        obj.key = this.key
      }
      return obj
    },
    validate(callback) {
      let flag = true;
      this.model.forEach(item => {
        const validateRes = this.validateField(item)
        if (!validateRes) {
          flag = false;
        }
      })
      callback(flag, this.getFormValue())
    }
  },
}
</script>

<style lang='scss' scoped>
.pg-form {
  font-size: .22rem;

  .form-item {
    position: relative;
    margin-bottom: 0.5rem;

    .input-img {
      width: 0.32rem;
      height: 0.32rem;
    }

    .input-with-select{
      ::v-deep .pg-input{
        .input-prefix{
          width: 1.88rem;
          .pg-select{
            border-color: transparent;
            .icon-flag{
              width: 0.28rem;
              height: 0.21rem;
            }
          }
        }
        input.no-suffix{
          padding-left: 1.88rem;
        }
      }
    }

    .phone{
      ::v-deep .pg-input{
        .input-prefix{
          width: 1.88rem;
          border-right: 0.01rem solid var(--theme-btm-def-color);
          padding: 5px 0;
        }
        input.no-suffix{
          padding-left: 2.3rem;
        }
      }
    }

    .flex {
      display: flex;
      justify-content: space-between;

      .captcha {
        width: 50%;
        padding-left: 0.1rem;
        box-sizing: border-box;
      }

      .captcha-img {
        width: 45%;
        height: 100%;
      }
    }

    .form-tip {
      position: absolute;
      bottom: -4px;
      font-size: .2rem;
      transition: transform .3s;
      transform: translateY(100%);

      &.tip-error {
        color: var(--theme-secondary-color-error);

        &::before {
          content: '';
          display: inline-block;
          width: 0.18rem;
          height: 0.18rem;
          color: var(--theme-secondary-color-error);
          background-image: url('@/assets/images/main.sprites.png');
          background-size: 9.36rem 8.658rem;
          border-radius: 50%;
          background-position: -8.856rem -6.633rem;
          margin-right: .1rem;
          background-color: var(--theme-secondary-color-error);
        }
      }
    }
  }

}
</style>