import { apiUserInfo, apiGetMoney } from "@/api";
const user = {
  namespaced: true,
  state: {
    token: "",
    money: 0,
    userInfo: {},
    getUserLoading: false,
  },
  mutations: {
    SET_USERINFO: (state, data) => {
      state.userInfo = data;
    },
    SET_TOKEN: (state, data) => {
      state.token = data;
    },
    SET_MONEY: (state, data) => {
      state.money = Number(data);
    },
    SET_LOADING: (state, data) => {
      state.getUserLoading = data;
    },
  },
  actions: {
    GetUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        commit("SET_LOADING",true);
        apiUserInfo()
          .then((res) => {
            commit("SET_LOADING",false);
            if (localStorage.getItem("avatar")) {
              res.data.avatar = localStorage.getItem("avatar");
            } else {
              const avatar = require(`@/assets/images/avatar/img_${
                res.data.gender == 0 ? "ntx" : "txn"
              }${Math.floor(Math.random() * 24)}.png`);
              res.data.avatar = avatar;
              localStorage.setItem("avatar", avatar);
            }
            commit("SET_USERINFO", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            commit("SET_LOADING",false);
            reject(err);
          });
      });
    },
    GetUserMoney({ commit }) {
      return new Promise((resolve, reject) => {
        console.log('localstorage',localStorage.getItem('token'));
        apiGetMoney()
          .then((res) => {
            commit("SET_MONEY", res?.data?.api_moneys?.[0]?.money);
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};

export default user;
