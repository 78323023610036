<template>
  <div class="bar-box">
    <span class="current-time" v-if="type == 'music'">{{ currentTime | formattedTime }}</span>
    <span v-else class="music">{{ $t('Music') }}</span>
    <div class="progress-bar-container" :class="{ 'is-volume': type == 'volume' }" @mousedown="startDrag"
      @touchstart="startDrag" @touchmove="dragging" @touchend="stopDrag">
      <div class="progress-bar-background" ref="progressBox">
        <div class="progress-bar-fill" :style="{ width: fillWidth }"></div>
      </div>
      <div class="handle-round" ref="handle" :style="{ left: handleLeft }"></div>
    </div>
    <span class="duration-time" v-if="type == 'music'">{{ duration | formattedTime }}</span>
    <Icon type="volume" class="volume" v-else />
  </div>

</template>
<script>
export default {
  data() {
    return {
      isDragging: false,
      startX: 0,
      startWidth: 0,
      handleWidth: 20,
      progressMaxWidth: 0, // 进度条容器的最大宽度
      currentProgress: 0, // 当前进度（例如0-100%）
      mouseStartX: 0,
    };
  },
  props: {
    currentTime: {
      type: Number,
      default: 0
    },
    duration: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: 'music'
    }
  },
  filters: {
    formattedTime(val) {
      let totalSeconds = parseInt(val);
      let hours = Math.floor(totalSeconds / 3600);
      let minutes = Math.floor((totalSeconds % 3600) / 60);
      let seconds = totalSeconds % 60;

      if (hours === 0) {
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
      } else {
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
      }
    },
  },
  computed: {
    fillWidth() {
      return `${this.currentProgress}%`;
    },
    handleLeft() {
      return `calc(${this.currentProgress}% - ${this.handleWidth / 2}px)`;
    },
  },
  watch: {
    currentTime: {
      handler(val) {
        if (this.isDragging) return
        this.currentProgress = val / this.duration * 100
      },
      immediate: true
    }
  },
  methods: {
    init() {
      this.progressMaxWidth = this.$refs.handle.parentNode.offsetWidth;
      this.handleWidth = this.$refs.handle.offsetWidth
    },
    changeCurrentTime(val) {
      if (val === 0) {
        this.currentProgress = 0
      } else {
        this.currentProgress = val / this.duration * 100
      }
    },
    startDrag(e) {
      if (e.type === 'mousedown') {
        this.isDragging = true;
        this.startX = e.clientX;
      } else if (e.touches && e.touches.length === 1) {
        this.isDragging = true;
        this.startX = e.touches[0].clientX;
      }
      const diffX = this.startX - this.$refs.progressBox.getBoundingClientRect().x
      const newWidth = Math.min(Math.max((diffX / this.progressMaxWidth) * 100, 0), 100);
      this.currentProgress = newWidth;
      this.startWidth = this.currentProgress;

      // 统一处理移动和结束事件的添加
      ['mousemove', 'touchmove'].forEach(eventName => {
        document.addEventListener(eventName, this.dragging, { passive: false });
      });
      ['mouseup', 'touchend'].forEach(eventName => {
        document.addEventListener(eventName, this.stopDrag);
      });
    },

    dragging(e) {
      if (!this.isDragging) return;
      let moveX;
      if (e.type === 'mousemove') {
        moveX = e.clientX;
      } else if (e.touches && e.touches.length === 1) {
        moveX = e.touches[0].clientX;
      }
      const diffX = moveX - this.startX;
      const newWidth = Math.min(Math.max(this.startWidth + (diffX / this.progressMaxWidth) * 100, 0), 100);
      this.currentProgress = newWidth;
    },

    stopDrag() {
      this.isDragging = false;
      this.$emit('change', this.duration * this.currentProgress / 100);
      ['mousemove', 'touchmove', 'mouseup', 'touchend'].forEach(eventName => {
        document.removeEventListener(eventName, this.dragging);
        document.removeEventListener(eventName, this.stopDrag);
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.bar-box {
  display: flex;
  align-items: center;
  font-size: .2rem;
  padding: 0 .39rem;

  .current-time {
    color: #fff;
    margin-right: .4rem;
  }

  .music {
    color: var(--theme-text-color-darken);
    font-size: .18rem;
    margin-right: 0.4rem;
  }

  .duration-time {

    color: #fff;
    margin-left: .4rem;
  }

  .progress-bar-container {
    width: 100%;
    height: .1rem;
    position: relative;

    &.is-volume {
      height: .18rem;
    }
  }

  .progress-bar-background {
    width: 100%;
    height: 100%;
    border-radius: .1rem;
    background-color: #ddd;
    position: absolute;
  }

  .progress-bar-fill {
    height: 100%;
    border-radius: .1rem;
    background: var(--theme-primary-color);
    position: absolute;
    left: 0;
  }

  .handle-round {
    width: .3rem;
    height: .3rem;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    touch-action: none;
  }

  .volume {
    color: var(--theme-primary-color);
    font-size: .4rem;
    margin-left: .4rem;
  }
}
</style>