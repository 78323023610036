<template>
 <button :class="['pg-button', `pg-btn-${type}`,round?'is-round':'', disabled ? 'pg-btn-disabled' : '', 'pg-size-' + size]"
    @click="disabled || loading ? '' : $emit('click')">
    <i class="loading-icon" v-if="loading">
      <Icon type="loading" />
    </i>
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'default'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'default'
    },
    round: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

    }
  },
  methods: {},
}
</script>

<style lang='scss' scoped>
.pg-button {
  display: inline-flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
  justify-content: center;
  border-radius: 0.14rem;
  font-size: .24rem;
  line-height: 0.7rem;
  box-shadow: 0 2px 0 rgba(0, 0, 0, .045);
  outline: 0;
  text-align: center;
  padding: 0 0.4rem;
  white-space: nowrap;
  list-style: none;
  transition: all .3s cubic-bezier(.645, .045, .355, 1);
  border: 1px solid transparent;
  &.is-round{
    border-radius: 1rem!important;
  }

  .loading-icon {
    width: 0.25rem;
    height: 0.25rem;
    margin-right: 0.1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    animation: roateAni .6s linear infinite;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &.pg-btn-default {
    background: transparent;
    border: 1px solid var(--theme-primary-color);
    color: var(--theme-primary-color);
  }

  &.pg-btn-primary {
    background-color: var(--theme-primary-color);
    border-color: var(--theme-primary-color);
    color: var(--theme-primary-font-color);
  }

  &.pg-btn-warn {
    background-color: var(--theme-secondary-color-finance);
    border-color: var(--theme-secondary-color-finance);
    color: var(--theme-secondary-color-help-text-color);
  }

  &.pg-btn-text {
    background: none;
    color: var(--theme-filter-active-color);
    border: transparent;
    box-shadow: none;
  }

  &.pg-btn-disabled {
    border-color: var(--theme-disabled-bg-color);
    background: var(--theme-disabled-bg-color);
    color: #fff;
  }

  &.pg-size-mid {
    font-size: 0.2rem;
    line-height: 0.5rem;
    padding: 0 0.4rem;
  }

  &.pg-size-mini {
    font-size: .2rem;
    height: 0.5rem;
    line-height: 1;
    padding: 0 0.05rem;
    width: 1.2rem;
    text-align: center;
    line-height: 1;
    white-space: pre-wrap
  }
}

.pc-page{
  .pg-button.pg-size-mini{
    height: .4rem;
    width: 1rem;
    border-radius: .1rem;
    font-size: .16rem;
  }
}
</style>