<template>
  <div class='empty-wrap '>
    <div class="empty-img">
      <img v-if="img" :src="img" alt="">
      <img v-else src="@/assets/images/img_none_jl.png" alt="">
    </div>
    <div class="em-text">{{ tip }}</div>
  </div>
</template>

<script>
export default {
  props: {
    tip: {
      type: String,
      default() {
        return this.$t('No_Records')
      }
    },
    img: {
      type: String,
      default() {
        return ''
      }
    }
  },
}
</script>

<style lang='scss' scoped>
.empty-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .empty-img {
    padding-bottom: 0.1rem;

    img {
      width: 2.1rem;
      height: 2.1rem;
      object-fit: contain;
    }
  }

  .em-text {
    color: var(--theme-text-color-lighten);
    font-size: .26rem;
  }
}
.pc-page{
  .empty-wrap {
    background: var(--theme-main-bg-color);
    border-bottom-right-radius: 0.1rem;
    border-bottom-left-radius: 0.1rem;
    padding-bottom: .5rem;
    box-sizing: border-box;
    width: auto;
    .report-footer {
      border-top: none;
    }
  }
}
</style>