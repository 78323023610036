<template>
  <span v-on="$listeners">{{animatePrice}}</span>
</template>

<script >
  export default {
    data(){
      return {
        animatePrice:0,
        timer:null
      }
    },
    props:{
      price:{
        type: Number,
        default:0
      }
    },
    watch:{
      price:{
        handler(val){
          clearInterval(this.timer);
          if(val===0){
            this.animatePrice = 0
          }else{
            this.addPrice(val)
          }
        },
        immediate:true
      }
    },
    methods:{
      addPrice(finallyNum) {
        let count = 0;
        const step = Number((finallyNum / (2000 / 30)).toFixed(2)) + 0.12;
        console.log('step,step',step,Number((finallyNum / (2000 / 30)).toFixed(2)))
        this.timer = setInterval(() => {
          if (count < finallyNum) {
            count += step;
            if (count > finallyNum) {
              count = finallyNum;
            }
            this.animatePrice = count.toFixed(2);
          } else {
            clearInterval(this.timer);
          }
        }, 30);
      }
    }
  }
</script>

<style scoped lang="scss">

</style>