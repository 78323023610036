<template>
  <div id="offer-page">
    <div class='offer-tebs' v-if="!Layout.isPc">
      <div class="offer-tab-arrow">
        <div class="arrow-wrap" v-show="scrollLeft > 0" @click="scrollLeftOrRight(false)">
          <Icon class="icon-arrow a-left " type="arrow-right-line" />
          <Icon class="icon-arrow a-left fade" type="arrow-right-line" />
        </div>
      </div>

      <div class="offer-scroll-tab-wrap">
        <div class="scroll-wrap" ref="tabScrollWrap" @scroll="handleScroll">
          <div class="tab-list" ref="tabList">
            <span class="offet-item"
              :class="{ active: item.key == 'Misson' ? missonShow : (missonShow ? false : current == index) }"
              v-for="(item, index) in tabList" :key="index" @click="handleChangeTabs(index)">{{ item.name }}</span>
          </div>
        </div>
      </div>
      <div class="offer-tab-arrow">
        <div class="arrow-wrap" v-show="scrollLeft < maxScrollLfet" @click="scrollLeftOrRight(true)">
          <Icon class="icon-arrow a-right fade " type="arrow-right-line" />
          <Icon class="icon-arrow  " type="arrow-right-line" />
        </div>
      </div>
    </div>
    <div class="offet-body">
      <keep-alive>
        <component :is="tabList[current]?.component"></component>
      </keep-alive>
    </div>
    <Mission ref="mission" @close="closeMission" />
  </div>
</template>

<script>
import Events from './component/Events'
import VIP from './component/VIP'
import Rebate from './component/Rebate'
import Interest from './component/Interest'
import Claim from './component/Claim'
import History from './component/History'
import Mission from '@/layout/components/Mission.vue'
export default {
  name: 'offers',
  data() {
    return {
      current: Number(this.$route.query.current) || 0,
      missonShow: false,
      tabList: [
        { name: this.$t('Event'), component: 'Events' },
        { name: this.$t('Misson'), key: 'Misson' },
        { name: 'VIP', component: 'VIP' },
        { name: 'Rebate', component: 'rebate' },
        { name: this.$t('interest'), component: 'Interest' },
        { name: this.$t('Claim'), component: 'Claim' },
        { name: this.$t('History'), component: 'History' },
        // { name: 'Juros' },
        // { name: 'Mais Bônus' }
      ],
      scrollLeft: 0,
      maxScrollLfet: 0,
      boxWidth: 0,
    }
  },
  inject: ['Layout'],
  watch: {
    current: {
      handler(val) {
        this.scrollView(val)
      },
      immediate: true
    },
    '$route'(val) {
      this.current = this.$route.query.current
    }
  },
  components: { Events, Rebate, VIP, Interest, Mission, Claim, History },
  mounted() {
    if (!this.Layout.isPc) {
      this.maxScrollLfet = this.$refs.tabList.clientWidth - this.$refs.tabScrollWrap.clientWidth
      this.boxWidth = this.$refs.tabScrollWrap.clientWidth
    }
  },
  methods: {
    handleChangeTabs(index) {
      if (this.tabList[index].key == 'Misson') {
        this.missonShow = true;
        this.$refs.mission.show()
        return
      }
      this.missonShow = false
      this.current = index;
      let url = `${this.$route.path}?current=` + index;
      history.replaceState(null, null, url)
    },
    scrollView(index) {
      this.$nextTick(() => {
        if (this.$refs.tabList) {
          let scrollBox = this.$refs.tabScrollWrap;
          let currentEl = this.$refs.tabList.children[index]
          if (currentEl) {
            scrollBox.scrollTo({
              left: currentEl.offsetLeft - scrollBox.offsetLeft - this.boxWidth / 2 + currentEl.clientWidth / 2,
              behavior: 'smooth'
            })
          }
        }
      })
    },
    closeMission() {
      this.missonShow = false;
    },
    scrollLeftOrRight(toEnd) {
      this.$refs.tabScrollWrap.scrollTo({
        left: toEnd ? this.maxScrollLfet : 0,
        behavior: 'smooth'
      })
    },
    handleScroll() {
      this.scrollLeft = this.$refs.tabScrollWrap.scrollLeft
    }
  },
}
</script>

<style lang='scss' scoped>
#offer-page {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: var(--theme-bg-color);

  .offer-tebs {
    display: flex;
    align-items: center;
    height: 0.9rem;
    background: var(--theme-main-bg-color);
    border-bottom: .01rem solid var(--theme-color-line);

    .offer-tab-arrow {
      width: 0.6rem;
      height: 100%;
      background: var(--theme-main-bg-color);
      position: relative;

      .arrow-wrap {
        width: 100%;
        height: 0.6rem;
        position: absolute;
        top: 50%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        transform: translateY(-50%);

        .icon-arrow {
          font-size: 0.2rem;
          color: var(--theme-primary-color);

          &.a-right {
            margin-right: -0.1rem;
          }

          &.a-left {
            transform: rotate(-180deg);

            &.fade {
              margin-left: -0.1rem;
            }
          }

          &.fade {
            color: rgba(var(--theme-primay-color-hex), .6);
          }
        }
      }
    }

    .offer-scroll-tab-wrap {
      overflow: hidden;
      font-size: .24rem;
      flex: 1;
      height: 0.9rem;
      position: relative;

      .scroll-wrap {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        line-height: 0.9rem;
        padding-bottom: 0.2rem;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;

        .tab-list {
          width: fit-content;
        }

        .offet-item {
          display: inline-block;
          color: var(--theme-text-color-darken);
          position: relative;
          margin-left: .6rem;

          &:first-child {
            margin-left: 0;
          }

          &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            width: 100%;
            background-color: var(--theme-primary-color);
            height: 0;
          }

          &.active {
            color: var(--theme-primary-color);

            &::after {
              height: 0.04rem;
            }
          }
        }
      }
    }
  }

  .offet-body {
    flex: 1;
    overflow: hidden;
    padding-bottom: 1.24rem;
  }
}

.pc-page{

  #offer-page {
    position: static;
    max-width: 12rem;
    margin: 0 auto;
    width: 100%;
    height: auto;
    background: transparent;
    padding-top: 0.2rem;
    box-sizing: border-box;

    .offet-body {
      margin: 0 .2rem;
      border-radius: 0.1rem;

      ::v-deep .of-main-wrap {
        .of-right-pc {
          .table-wrap {
            .pg-container {
              .pg-table {
                background: var(--theme-bg-color);
              }
            }
          }
        }
      }
    }
  }
}
</style>