export default {
    Home: "Inicio",
    offers: "Ofertas",
    Profile: "Perfil",
    Profile_me_info: "Perfil",
    Withdrawal: "Retiro",
    Customer_service: "Atención al cliente",
    login: "Iniciar sesión",
    register: "Registrarse",
    deposit: "Depósito",
    withdraw: "Retirar",
    Withdraw_Records: "Registros de retiros",
    Audit_Report: "Informe de auditoría",
    Manage_Account: "Administrar cuenta",
    Balance: "Saldo",
    Normal_withdrawal: "Retiro normal",
    Withdrawal_Password: "Contraseña de retiro",
    Not_Started: "No iniciado",
    Completed: "Completado",
    Ongoing: "En proceso",
    interest: "Interés",
    dont_show: "No mostrar de nuevo hoy",
    Casino: "Casino",
    Reward: "Recompensa",
    Agent: "Agente",
    Event: "Evento",
    APP_download: "Descargar APP",
    Mission: "Misión",
    Games: "Juegos",
    Support: "Soporte",
    Online_Service: "Servicio en línea",
    Help_Center: "Centro de ayuda",
    Message_Center: "Centro de mensajes",
    forgot_password: "Olvidé mi contraseña",
    Remmeber_Me: "Recordarme",
    Register_An_Account: "Registrar una cuenta",
    Username: "Nombre de usuario",
    Name: "Nombre",
    user_tip: "4-16 caracteres, soporta inglés/números",
    password: "Contraseña",
    password_tip: "6-16 caracteres",
    password_agin_tip: "Las contraseñas no coinciden. Por favor, reintroduzca.",
    Re_enter_password: "Reintroducir contraseña",
    over_tip: "Tengo más de 18 años, he leído y estoy de acuerdo con el",
    User_Agreement: "Acuerdo del usuario",
    I_have_read: "He leído",
    user_agreement_1: `1. Para evitar disputas de apuestas, los miembros deben leer las reglas de la compañía antes de ingresar. Una vez que el jugador "acepta" al ingresar a esta compañía para apostar, se considerará que está de acuerdo con el Acuerdo del usuario de la compañía.`,
    user_agreement_2: `2. Es responsabilidad del miembro garantizar la confidencialidad de su cuenta e información de inicio de sesión. Cualquier apuesta en línea realizada con su número de cuenta y contraseña de miembro será considerada válida. Cambie su contraseña de vez en cuando. La compañía no tiene responsabilidad por cualquier compensación de apuestas realizadas con cuentas y contraseñas robadas.`,
    user_agreement_3: `La compañía se reserva el derecho de modificar este acuerdo o las reglas del juego o las reglas de confidencialidad de vez en cuando. Los términos modificados entrarán en vigor en la fecha especificada después de que ocurra el cambio, y se reserva el derecho a tomar decisiones finales en todos los conflictos para la compañía.`,
    user_agreement_4: `4. Los usuarios deben ser mayores de edad de acuerdo con las leyes del país de residencia para usar el casino o la aplicación en línea. Las apuestas en línea que no se hayan enviado exitosamente se considerarán nulas.`,
    user_agreement_5: `5. Cuando un jugador se desconecta automáticamente o forzosamente del juego antes de que se revele el resultado del juego, esto no afectará el resultado del juego.`,
    Messages: "Mensajes",
    Account: "Cuenta",
    Main_Wallet: "Billetera principal",
    To_achieve_next_level: "Para alcanzar el siguiente nivel",
    Betting_still_lacks: "Apuestas aún faltantes",
    Upgrade_Needs_Wager: "Actualización necesita apuesta",
    Account_Details: "Detalles de la cuenta",
    Bet_Records: "Registros de apuestas",
    Report: "Informe",
    Reports: "Informes",
    Withdrawal_Management: "Gestión de retiros",
    Withdrawal_Method: "Método de retiro",
    million_monthly: "millón mensual",
    Security_Center: "Centro de seguridad",
    Language: "Idioma",
    Feedback_to_get_rewards: "Comentarios para obtener recompensas",
    Quit: "Salir",
    Login_Now: "Iniciar sesión ahora",
    First_deposit_upon_registration: "Primer depósito al registrarse",
    Bonus: "Bonificación",
    Collected: "Recogido",
    Task_time: "Tiempo de tarea",
    bonus_1: "Tarea a largo plazo (cada nueva cuenta solo puede participar una vez)",
    bonus_2: "II.Condiciones de la tarea:",
    bonus_2_content: "Completar configuraciones relacionadas con la cuenta y enlaces de seguridad",
    bonus_3: "III.Contenido de la tarea:",
    bonus_3_content: `1.Cada nueva cuenta registrada puede completar las tareas anteriores, y al completarlas, recibirá una cierta cantidad de bonificación. Cuanto mayor sea la dificultad, mayor será la recompensa.<br>2.Dado que cada cuenta es completamente anónima, una vez robada, resultando en pérdida de fondos, no será recuperable. Por lo tanto, exigimos vincular la verificación en dos pasos, especialmente al agregar direcciones de retiro, para probar que es una operación personal y asegurar su seguridad.<br>3.Condiciones cumplidas para reclamar directamente, y pueden reclamarse directamente en cualquier plataforma; expirarán si no se reclaman (la falta de reclamo se considera una renuncia voluntaria).<br>4.Debido a la bonificación relativamente alta para esta tarea, la bonificación requiere 2 veces de auditoría (es decir, auditoría, apuestas o apuestas válidas) para retirar, sin restricciones sobre plataformas de juegos.<br>5.Esta tarea es exclusivamente para los titulares de cuentas normales manuales; alquilar, usar trampas, bots, apuestas cruzadas entre cuentas, cepillado mutuo, arbitraje, API, protocolos, explotar vulnerabilidades, controles grupales u otros medios técnicos están prohibidos. Las violaciones resultarán en la cancelación o deducción de recompensas, congelamiento de cuentas o incluso colocación en una lista negra.<br>6.Para evitar diferencias en la comprensión del texto, la plataforma retendrá la interpretación final de este evento.`,
    Accumulated_bets_made: "Apuestas acumuladas realizadas",
    Go: "Ir",
    Deposit_Records: "Registros de depósitos",
    Online_Deposit: "Depósito en línea",
    Deposit_Amount: "Cantidad de depósito",
    Bonus_event_explanation: "Explicación del evento de bonificación",
    Deposit_Now: "Depositar ahora",
    The_first_deposit_to_receive: "El primer depósito para recibir",
    Total_Deposit: "Depósito total",
    Deposit_Details: "Detalles del depósito",
    Today: "Hoy",
    Yesterdaty: "Ayer",
    Days: "Días",
    Day: "Día",
    DAY: "DÍA",
    All: "Todos",
    Total_Withdrawal: "Retiro total",
    Total_Withdraw: "Retiro total",
    Accumulated_Claimed_Benefits: "Beneficios acumulados reclamados",
    Transaction_Type: "Tipo de transacción",
    Action: "Acción",
    Deposit_Method: "Método de depósito",
    Deposit_channels: "Canales de depósito",
    Time_Created: "Fecha de creación",
    Order_No: "Número de orden",
    Newbie_Bonus: "Bonificación para nuevos usuarios",
    Daily_Mission: "Misión diaria",
    Claim_All: "Reclamar todo",
    History: "Historial",
    Rebate: "Reembolso",
    Claim: "Reclamar",
    Claim_1: "Reclamar",
    Claimed: "Reclamado",
    Next: "Siguiente",
    No_Records: "Sin registros",
    Level: "Nivel",
    Valid_Bets: "Apuestas válidas",
    First_Deposit: "Primer depósito",
    Online_login_and_counts: "Desconectado",
    Online_login_and_counts_pc: "Inicio de sesión en línea y conteo",
    Commission: "Comisión",
    Agent_Network: "Red de agentes",
    Referral_Link: "Enlace de referencia",
    Performance: "Rendimiento",
    Subordinate_s_Wagers: "Apuestas de subordinados",
    All_data: "Todos los datos",
    Subordinate_stats: "Estadísticas de subordinados",
    Agent_Bonus_Rate: "Tasa de bonificación de agente",
    Collectable: "Reclamable",
    Agent_Tier: "Nivel de agente",
    Promotion_conditions: "Condiciones de promoción (rendimiento acumulativo)",
    Total_Pending_Audit: "Total pendiente de auditoría",
    Audit_Status: "Estado de auditoría",
    Funds_Change: "Cambio de fondos",
    Number_of_audits: "Número de auditorías",
    Total_Audit_Required: "Total de auditorías requeridas",
    Pending_audit: "Pendiente de auditoría",
    Audited: "Auditorizado",
    Limited_to_the_platform: "Limitado a la plataforma",
    Game_Limited: "Juego(s) limitado(s)",
    Total_Bet_Lucky_Spin: "Apuesta total en Lucky Spin",
    My_Link: "Mi enlace",
    Quick_Share: "Compartir rápidamente",
    valid_s: "Subordinados válidos",
    people: " personas",
    Details: "Detalles",
    box_tip: "Qué es un número válido de referencias? (Cumpliendo simultáneamente las siguientes condiciones)",
    the_s: "El depósito total del subordinado",
    the_turnover: "El volumen total de negocios del subordinado",
    Or_the_above: "O lo anterior",
    Create: "Crear",
    Mine: "Mío",
    Feedback_Content: "Contenido del comentario",
    Suggestions_for_revision: "Sugerencias para revisión",
    Attachment: "Adjunto",
    Easier_to_be_adopted: "Más fácil de adoptar",
    Reupload: "Volver a cargar",
    upload_tip: "Soporta formato de imagen y video, tamaño no debe exceder 50MB",
    Reward_Rules: "Reglas de recompensas",
    Reward_Rules_content: "Hemos establecido una gran bonificación para recoger comentarios específicamente para optimizar nuestro sistema y características para una mejor experiencia para usted. Una vez adoptados, las recompensas se otorgarán según la importancia (excluyendo los no adoptados).",
    Submit_feedback: "Enviar comentarios",
    No_Messages: "Sin mensajes",
    online_tip: "El servicio al cliente profesional y a tiempo completo siempre está en línea para ayudarlo con cualquier pregunta.",
    plat_sup: "Soporte",
    News: "Noticias",
    Notification: "Notificación",
    Marquee: "Cinta deslizante",
    daily_i_1: "Ingresado continuamente durante",
    daily_i_2: "días",
    Required_deposit: "Depósito requerido",
    Required_bets: "Apuestas requeridas",
    daily_rule_title_1: "I.Requisito del evento:",
    daily_rule_content_1: "Depósito + Apuestas (reinicia después de 7 días consecutivos)",
    daily_rule_title_2: "II.Contenido del evento:",

    daily_rule_content_2: `1.Se requiere un depósito y apuestas diarias que cumplan con las condiciones del evento para un registro exitoso y reclamar
  bonificaciones fijas o misteriosas, hasta 19.
  2.Esté evento es un evento de registro consecutivo (reanuda desde el día 1 en caso de interrupciones);
  3.Las recompensas solo pueden ser reclamadas manualmente en iOS, Android, H5, PC.
  4.El bono otorgado en este evento (excluyendo el capital) requiere 1 vez de apuesta (es decir, verificación, apuestas o apuestas válidas) para retirar, las apuestas no están limitadas a una plataforma de juego;
  5.Esté evento solo es para el propietario de la cuenta con apuestas normales manuales, alquiler, uso de software de trampa, bots,
  apuestas entre diferentes cuentas, manipulación mutua, arbitraje, APIs, protocolos, explotación de vulnerabilidades, control grupal u otros medios técnicos están prohibidos, de lo contrario, las recompensas pueden ser canceladas o
  deducidas, o las cuentas pueden ser congeladas o incluso incluidas en una lista negra;
  6.Para evitar diferencias en la comprensión del texto, la plataforma retendrá la interpretación final de este evento.`,
    Back: "Atrás",
    Hot: "Caliente",
    Recent: "Reciente",
    Distributed: "Distribuido",
    Deposited: "Depositado",
    Transfer_in: "Transferencia entrante",
    Transfer_out: "Transferencia saliente",
    Settlement_period: "Período de liquidación",
    hours: "horas",
    Total_claimed: "Total reclamado",
    Interest_ceiling: "Techo de interés",
    No_restrictions: "Sin restricciones",
    Total_Earnings: "Ganancias totales",
    Record_details: "Detalles del registro",
    Interest_rules: "Reglas de interés",
    Interest_tips: "Tasa Anual del 15%",
    Time: "Tiempo",
    Type: "Tipo",
    Amount: "Cantidad",
    Rebate_Records: "Registros de reembolsos",
    Rebate_rate: "Tasa de reembolso",
    Receive_amount: "Cantidad recibida",
    Rebateable: "Reembolsable",
    to_level: "Para alcanzar el siguiente nivel",
    bsl: "Apuestas aún faltantes",
    VIP_Level_List: "Lista de niveles VIP",
    Promotion_Bonus: "Bonificación de promoción",
    Weekly_Bonus: "Bonificación semanal",
    Monthly_Bonus: "Bonificación mensual",
    Tips: "Consejos",
    tips_content: `Para avanzar al siguiente nivel, basándose en las apuestas válidas acumuladas existentes, se requieren apuestas adicionales. Por ejemplo, para ascender a VIP 1, se necesitan 1000 apuestas válidas acumuladas, y para ascender a VIP 2, se necesitan 2000 apuestas válidas acumuladas. Por lo tanto, para llegar a VIP 2, un jugador necesita un total de 3000 apuestas válidas acumuladas (1000 + 2000), y así sucesivamente.`,
    vip_rule_title: "Explicación de reglas VIP",
    vip_rule: `1.Estandar de promoción: Cumplir los requisitos de promoción VIP (es decir, tanto el depósito como las apuestas válidas deben cumplir los criterios) para ascender al nivel VIP correspondiente y recibir la bonificación de promoción correspondiente. Si se asciende varios niveles consecutivamente, se pueden recibir todas las bonificaciones de promoción de nivel. Las bonificaciones en tiempo real pueden ser reclamadas;
  2.Bonificación diaria: Al cumplir los requisitos de depósito y apuestas válidas del nivel actual cada día, se puede recibir la bonificación diaria correspondiente. Si se asciende varios niveles consecutivamente, solo se puede obtener la bonificación diaria del nivel actual. Las bonificaciones en tiempo real pueden ser reclamadas;
  3.Bonificación semanal: Al cumplir los requisitos de depósito y apuestas válidas del nivel actual cada semana, se puede recibir la bonificación semanal correspondiente. Si se asciende varios niveles consecutivamente, solo se puede obtener la bonificación semanal del nivel actual. Las bonificaciones en tiempo real pueden ser reclamadas;
  4.Bonificación mensual: Al cumplir los requisitos de depósito y apuestas válidas del nivel actual cada mes, se puede recibir la bonificación salarial mensual correspondiente. Si se asciende varios niveles consecutivamente, solo se puede obtener la bonificación mensual del nivel actual. Las bonificaciones en tiempo real pueden ser reclamadas;
  5.Tiempo de expiración de la recompensa: El bono recibido se retiene durante %d días. Si no se reclama activamente durante este período, se acreditará automáticamente en la cuenta. Por ejemplo: si se obtiene una recompensa el 1 de enero y se retiene durante %d días, se acreditará automáticamente en la cuenta el %d de enero a las 00:00:00.
  6.Explicación de mantenimiento: Después de alcanzar un cierto nivel VIP, si el depósito y las apuestas válidas de un miembro del mes anterior no cumplen los requisitos de mantenimiento, se degradará automáticamente un nivel.
  7.Nota de auditoría: Las bonificaciones VIP deben ser apostadas 1 vez (es decir, auditadas, jugadas o a través de apuestas válidas) para ser elegibles para retirar, las apuestas no están limitadas a ninguna plataforma de juego;
  8.Declaración del evento: Esta función solo está destinada al propietario de la cuenta para apuestas de juego normales. Alquilar cuentas, apuestas sin riesgo (emparejamiento, cepillado, cuotas bajas), arbitraje malicioso, uso de complementos, bots, explotación de acuerdos, vulnerabilidades, interfaces, control grupal u otros medios técnicos están prohibidos. Una vez verificado, la plataforma se reserva el derecho de terminar los inicios de sesión de los miembros, suspender el uso del sitio web por parte de los miembros y confiscar bonificaciones y ganancias indebidas sin previo aviso.
  9.Explicación: Al reclamar las recompensas VIP, se asume que el miembro está de acuerdo en cumplir con las condiciones correspondientes y las regulaciones relacionadas. Para evitar malentendidos en la interpretación del texto, la plataforma se reserva el derecho de interpretar finalmente este evento.`,
    Misson: "Misión",
    Set_Up: "Configurar",
    Link: "Enlace",
    Please_link_your_mobile_phone: "Por favor, enlace su teléfono móvil",
    Please_link_your_mail: "Por favor, enlace su correo electrónico",
    birth_tip: "Fecha de nacimiento (no se puede modificar una vez establecida)",
    pltwaf: "Por favor, enlace primero la cuenta de retiro",
    ped: 'Ingrese 6-40 dígitos',
    plw: 'Ingrese WhatsApp',
    pefa: "Ingrese cuenta de Facebook",
    pet: 'Ingrese Telegram',
    eut: 'Ingrese hasta 5-40 ',
    Year: "Año",
    Save: "Guardar",
    Male: "Masculino",
    Female: "Femenino",
    Statement: "Declaración",
    Cumulative_bet_amount: "Cantidad total de apuestas",
    Total_Valid_Bets: "Apuestas válidas totales",
    Total: "Total W/L",
    nob: "Número de apuestas",
    ba: "Cantidad de apuesta",
    Ganhos: "W/L",
    Verify_Login_Password: "Verificar contraseña de inicio de sesión",
    you_tip: "Puede enlazar el teléfono después de verificar la contraseña de inicio de sesión",
    Other_Verification_Methods: "Otros métodos de verificación",
    The_above_method_is_not_available: "El método anterior no está disponible?",
    Contact_Us: "Contáctenos",
    Enter_Password: "Ingrese contraseña",
    Mobile_Phone: "Teléfono móvil",
    phone: "Ingrese número de teléfono",
    Player_ID: "ID del jugador",
    Unlinked: "Desenlazado",
    Email: "Correo electrónico",
    Login_Password: "Contraseña de inicio de sesión",
    Security_Question: "Pregunta de seguridad",
    Link_ThLink_Third_party_Login: "Link Third-party Login",
    Unset: "Unset",
    set_title: "It's your first withdrawal, you need to set a withdrawal password first",
    set_up: "Set Up Withdrawal Password",
    New_Withdrawal: "New Withdrawal Password",
    Old_Withdrawal: "Old Withdrawal Password",
    password_note: `Note: Your withdrawal password protects the security of your funds. It is very important and
    should only be known to yourself to avoid any financial loss.`,
    confirm: "confirm",
    Confirm: "Confirm",
    Current_Lucky_Value: "Current Lucky Value",
    you_still_need: "You still need to wager",
    to_get: "to get",
    Lucky_Points: "Lucky Points",
    Instant: " Instant<br>Draw",
    Luck_Value: "Luck Value",
    Silver: "Silver",
    Golden: "Golden",
    Diamond: "Diamond",
    Reward_announcement: "Reward announcement",
    My_Records: 'My Records',
    won_at: "won at",
    returntable_rule_content: "Accumulated valid bets (i.e., specified platform's bets or audit)",
    Designated_platform: "Designated platform",
    return_table_content_2: `1.During the event period, every 1 valid bet automatically earns 1 lucky point. 1000-10000 lucky
    points allow one draw, with the maximum reward being 9999.<br>
    2.Lucky points obtained today and unused by the next day will expire and reset to zero;<br>
    3.Rewards can only be manually claimed in iOS、Android、H5、PC<br>
    4.The bonus given in this event (excluding the principal) requires 1 times rollover (i.e., verification,
    wagering, or valid betting) for withdrawal, wagering is not limited to a game platform;<br>
    5.This event is only for the account owner's normal manual operatens, renting, using cheating software,
    bots, gambling between different accounts, mutual manipulation, arbitrage, APIs, protocols, exploiting
    vulnerabilities, group control, or other technical means are prohibited, otherwise rewards may be
    canceled or deducted, or accounts may be frozen or even blacklisted;<br>
    6.In order to avoid differences in text understanding, the platform will retain the final interpretation
    of this event.`,
    twde: "Total wagers during the event period",
    Used_Lucky_Value: "Used Lucky Value",
    Expired_Lucky_Value: "Expired Lucky Value",
    Available_Lucky_Value: "Available Lucky Value",
    Claim_Successfully: "Claim Successfully",
    Total_Account_Balance: "Total Account Balance",
    One_click_transfer: "One click transfer",
    Live: "Live",
    Fishing: "Fishing",
    Sports: "Sports",
    Cards: "Cards",
    Lottery: "Lottery",
    Blockchain: "Blockchain Games",
    Slots: "Slot",
    All_Type: "All Type",
    ALL_Platform: "All Platform",
    Platform: "Platform",
    Total_Commission: "Total Commission",
    Referral_information: "Referral information",
    More: "More",
    Commission_Collected: "Commission Collected",
    Last_Commission: "Last Commission",
    Total_Members: "Total Members",
    Total_angent: "Total",
    Direct_Subordinates: "Direct Subordinates",
    Others: "Others",
    Total_Perf: "Total Perf",
    Sub_Perf: "Sub's Perf",
    Others_Perf: "Others' Perf",
    Subordinate_Wagers: "Subordinate's Wagers",
    Total_valid_bets: "Total valid bets",
    total_no: "Total no. of bet",
    Total_angent_wl: "Total W/L",
    Withdrawal_Account: "Withdrawal Account",
    Hide: "Hide",
    Show: "Show",
    Add_New_Bank_Account: "Add New Bank Account",
    Enter_PIN: "Enter PIN",
    for_you_account: "For your account safety, please enter the withdrawal password",
    pix_tip: "PIX Account cannot be empty",
    digits_11: "Please enter 11 digits",
    digits_6: "Please enter 6 digits",
    Set_as_Default: "Set as Default",
    Add_withdrawal_account: "Add withdrawal account",
    add_account_tip: "Please select a withdrawal account first",
    with_tip_1: "The remaining wagering amount required for withdrawal is",
    with_tip_2: " ",
    Min: 'Min',
    Max: 'Max',
    Withdrawal_Amount_cannot_be_empty: 'Withdrawal Amount cannot be empty',
    withdraw_err: "Withdrawal not available until the audit is completed",
    wp_can_be_empty: "Withdrawal Password cannot be empty",
    Can_be_collected_today: "Can be collected today",
    Fee: "Fee",
    Add: "Add",
    All_status: "All status",
    Not_settled: 'Not settled',
    Settled: 'Settled',
    Order_canceled: "Order canceled",
    Confirm_New_Withdrawal_Password: "Confirm New Withdrawal Password",
    Search: "Search",
    Search_Games: "Search Games",
    tab_Search: "Search",
    Favorite: "Favorite",
    Reports_tab: "Report",
    em_claim: "Currently no available rewards to claim",
    Start_Date: "Start Date",
    End_Date: "End Date",
    Cancel: "Cancel",
    My_ID: "My ID",
    Contribution_Commission: "Contribution Commission",
    total_first: "Total first-deposit players",
    Other_first_deposit: "Other first deposit",
    Other_deposit: "Other deposit",
    Direct_first_deposit: "Direct first deposit",
    Subordinate_Deposits: "Subordinate Deposits",
    Subordinate: "Subordinate",
    Full_Screen: "Full Screen",
    Refresh: "Refresh",
    Yes: "Yes",
    No: "No",
    Settlement_date: "Settlement date",
    Contributors: "Contributors",
    Joining_time: "Joining time",
    Member_ID: "Member ID",
    Statistical_date: "Statistical date",
    Number_of_bets: "Number of bets",

    Total_W_L: "Total W/L",
    bounusTip: "Downline members will only contribute rebates if their deposits ≥0 and have made valid bets ≥0 during the settlement period.",
    Type_Details: "Type Details",
    Bet_ID: "Bet ID",
    Bet_amount: "Bet amount",
    In_game: "In-game use",
    page: 'page',
    Page: 'Page',
    entries: 'entries',
    page_total_1: "Total of",
    page_total_2: "entries",
    more_1: "Currently displaying",
    more_2: "Hot games out of",
    more_3: "",
    Load_More: "Load More",
    Reminder: "Reminder",
    Log_out_from_account: "Log out from account",
    Cashback_Exclusivo: "Cashback Exclusivo",
    privileges_Exclusivo: "VIP privileges",
    login_password_tip: 'Modify the login password after verifying the login password',
    Confirm_the_new_password: "Confirm the new password",
    Daily_loss_rescue_fund: "Daily loss rescue fund",
    Loss_amount: "Loss amount",
    Extra_Rewards: "Extra Rewards",
    Yesterday_s_losses: "Yesterday's losses",
    Today_s_Rescue_Bonus: "Today's Rescue Bonus",
    Lucky_wager: "Lucky wager",
    No_winning_wager_today: "No winning wager today",
    max_times_claimed: "Max. {num} times claimed today ({num} times left)",
    Bet_number_last_digits: "Bet number last digits",
    Reward_multiple: "Reward multiple",
    Reward_limit: "Reward limit",
    reward_amount: "Reward amount = Bet amount of that bet slip x Bonus multiplier, without exceeding the limit",
    Music: 'Music',
    Cycle: 'Cycle',
    Shuffle: 'Shuffle',
    Repeat: 'Repeat',
    Downloaded: 'Downloaded',
    System_Music: "System Music",
    My_music: "My music",
    change_password_note: "Attention: The withdrawal password protects your funds and is extremely important. Keep it to yourself to prevent any financial loss",
    Change_Withdrawal_Password: 'Change Withdrawal Password',
    Link_Email: 'Link Email',
    Enter_Email: 'Enter Email',
    Deslize_para: 'Deslize para concluir o puzzle',
    length_tip: '{lengthRange}dígitos',
    Numbers: 'Numbers',
    Lowercase: 'Lowercase',
    Uppercase: 'Uppercase',
    My_superiors: 'My superiors',
    Agent_mode: 'Agent mode',
    Unlimited_level: 'Unlimited level difference (Daily settlement)',
    OfferCenter: 'Offer Center',
    SupportCenter: 'Support Center',
    OfficialChannel:'Official channel',

    pleaseOpenYour: "Por favor, abre tu aplicación de pago y escanea o copia y pega el código QR a continuación para completar tu compra;",
    This_QR_code: "Este código QR solo puede ser pagado una vez. Si necesitas pagar de nuevo, por favor vuelve y recarga;",
    After_the_payment: "Después de que el pago sea exitoso, puedes volver al vestíbulo del juego y esperar a que se añadan los puntos!",
    Effective_time: "Tiempo efectivo",
    Copy_QR_code: "Copiar código QR",
    QR_code_address: "Dirección del código QR",
    Order_Status: "Estado del pedido",
    To_be_paid: "Por pagar",
    Creation_Time: "Tiempo de creación",
    Order_Number: "Número de pedido",
    Merchant_order_number: "Número de pedido del comerciante",
    success:'éxito',
    failure:'Fracaso'
};