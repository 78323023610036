import Vue from "vue";
import App from "./App.vue";
import router from "@/router";
import PgInput from "@/components/PgInput";
import Icon from "@/components/Icon";
import PgCheck from "@/components/PgCheck";
import PgButton from "@/components/PgButton";
import PgTabs from "@/components/PgTabs";
import PgForm from "@/components/PgForm";
import PgDrawer from "@/components/PgDrawer";
import PgPassword from "@/components/PgPassword";
import PgProgress from "@/components/PgProgress";
import MenuBtn from "@/components/MenuBtn";
import Empty from "@/components/Empty";
import Tabs from "@/components/Tabs.vue";
import PgTable from "@/components/PgTable.vue";
import PgNav from "@/components/PgNav.vue";
import Drop from "@/components/drop";
import Bubble from "@/components/Bubble";
import PgSelect from "@/components/PgSelect";
import PgRefresh from "@/components/PgRefresh";
import PgAvatar from "@/components/PgAvatar";
import PgPoup from "@/components/PgPoup";
import scrollbar from "@/components/scrollbar";
import PgDatePicker from "@/components/PgDatePicker";
import PgPagination from "@/components/pagination";
import pgImage from "@/components/pgImage";
import PgDragProgress from "@/components/PgDragProgress";
import PgPrice from "@/components/PgPrice.vue";
import { Progress,Collapse,CollapseItem } from "element-ui";
import './assets/icons';

import EventList from "@/components/EventList";
import "swiper/css/swiper.css";
import directives from "./directives";
import VueAwesomeSwiper from "vue-awesome-swiper";
import store from "./store";
import message from "@/util/message";
import filters from "@/filters";
import {  Scrollbar } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import i18n from "./lang";
import 'vant/lib/datetime-picker/style';
import { DatetimePicker } from 'vant';
import'@/assets/css/common.scss';
import'@/assets/css/element.scss';

Vue.use(DatetimePicker);
Vue.config.productionTip = false;
Vue.component("PgInput", PgInput);
Vue.component("Icon", Icon);
Vue.component("PgCheck", PgCheck);
Vue.component("PgButton", PgButton);
Vue.component("PgTabs", PgTabs);
Vue.component("PgForm", PgForm);
Vue.component("PgDrawer", PgDrawer);
Vue.component("PgPassword", PgPassword);
Vue.component("PgProgress", PgProgress);
Vue.component("MenuBtn", MenuBtn);
Vue.component("Empty", Empty);
Vue.component("Tabs", Tabs);
Vue.component("PgTable", PgTable);
Vue.component("PgNav", PgNav);
Vue.component("Drop", Drop);
Vue.component("Bubble", Bubble);
Vue.component("PgSelect", PgSelect);
Vue.component("PgRefresh", PgRefresh);
Vue.component("PgAvatar", PgAvatar);
Vue.component("PgPoup", PgPoup);
Vue.component("scrollbar", scrollbar);
Vue.component("EventList", EventList);
Vue.component("pgImage", pgImage);
Vue.component("PgDragProgress", PgDragProgress);
Vue.component("PgPrice", PgPrice);

Vue.component("PgDatePicker", PgDatePicker);
Vue.component(Progress.name, Progress);
Vue.component(Collapse.name, Collapse);
Vue.component(CollapseItem.name, CollapseItem);

Vue.use(PgPagination);
Vue.use(Scrollbar);
Vue.use(VueAwesomeSwiper);
Vue.use(filters);
Vue.use(directives);

Vue.prototype.$message = message;
if (process.env.NODE_ENV === "production") {
  window.console.log = function () {};
}

new Vue({
  render: (h) => h(App),
  router,
  store,
  i18n,
}).$mount("#app");
