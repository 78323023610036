<template>
  <div class="of-main-wrap" v-loading="loading">
    <div class="of-left">
      <MenuBtn icon="menu" :text="$t('All')" :active="true" />

      <PgButton type="primary" :disabled="true">{{ $t('Claim_All') }}</PgButton>
      <PgButton type="primary">{{ $t('History') }}</PgButton>
    </div>
    <div class="of-right">
      <div class="of-right-item-wrap" v-for="(item, index) in storeList" :key="index" @click="toPage(item.url)">
        <div class="of-right-item">
          <img :src="item.cover_image" alt="">
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { apiGetActivities } from '@/api'
export default {
  name: 'Events',
  data() {
    return {
      storeList: [],
      loading: false
    }
  },
  created() {
    this.getList()
  },
  methods: {
    toPage(url) {
      this.$router.push(url)
    },
    getList() {
      this.loading = true;
      apiGetActivities().then(res => {
        this.loading = false;
        this.storeList = res.data;
      }).catch(err => {
        this.loading = false;
      })
    }
  },
}
</script>

<style lang='scss' scoped>
.of-main-wrap {
  height: 100%;
  display: flex;

  .of-left {
    padding-top: 0.2rem;
    overflow-y: auto;
    width: 1.9rem;
    font-size: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    ::v-deep .pg-button {
      width: 1.2rem;
      font-size: 0.14rem;
      line-height: 0.5rem;
      margin: 0.2rem auto 0;
      padding: 0 .05rem;
    }
  }

  .of-right {
    flex: 1;
    width: 1rem;
    overflow-y: scroll;

    .of-right-item-wrap {
      .of-right-item {
        background-color: var(--theme-main-bg-color);
        border-radius: 0.1rem;
        padding: 0.1rem;
        position: relative;
        margin: 0.1rem;

        img {
          width: 100%;
          display: block;
        }
      }
    }

  }
}

.pc-page{
  .of-main-wrap {

    .of-right {
      font-size: 0;

      .of-right-item-wrap {
        width: 50%;
        display: inline-block;
        box-sizing: border-box;
        padding: 0 0.1rem;


        .of-right-item {
          margin: 0 0 0.1rem 0;
          box-sizing: border-box;
        }
      }

    }
  }
}
</style>