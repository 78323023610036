export default {
    Home: "Inicio",
    offers: "Promoção",
    Profile: "Perfil",
    Profile_me_info: "Informações pessoais",
    Withdrawal: "Saque",
    Customer_service: "Serviço de clientes",
    login: "Entrar",
    register: "Registro",
    deposit: "Depósito",
    withdraw: "Saque",
    Withdraw_Records: "Registro de Retiradas",
    Audit_Report: "Registros de Auditoria",
    Manage_Account: "Gerenciar Conta",
    Balance: "Saldo da Conta",
    Normal_withdrawal: "Retirada Padrão",
    Withdrawal_Password: "Senha de Saque",
    Not_Started: "Não Começou",
    Completed: "Completado",
    Ongoing: "Em curso",
    interest: "Juros",
    dont_show: "Não mostrar novamente hoje",
    Casino: "Cassino",
    Reward: "Bônus",
    Agent: "Convidar",
    Event: "Evento",
    APP_download: "Baixar App",
    Mission: "Missão",
    Games: "Jogos",
    Support: "Apoio",
    Online_Service: "Apoio online",
    Help_Center: "Centro de Ajuda",
    Message_Center: "Centro de mensagens",
    forgot_password: "Esqueceu a Senha?",
    Remmeber_Me: "Lembrar Senha",
    Register_An_Account: "Registra uma Conta",
    Username: "Nome de Usuário",
    user_tip: "4-16 caráter bit, suporte em inglês/números",
    password: "Senha",
    password_tip: "6-16 caracteres",

    password_agin_tip: "Senha incorreta, digite novamente!",
    Re_enter_password: "Senha incorreta, digite novamente!",
    over_tip: "Tenho 18 anos, li e concordo com ",
    User_Agreement: "Acordo do Usuário",
    I_have_read: "Lido e Compreendido",
    user_agreement_1: `1. Para evitar disputas de apostas, os membros devem ler as regras da empresa antes de entrar no
  aplicativo. Uma vez que o jogador“ Eu concordo” Ao entrar nesta empresa para apostar, será
  considerado que você está de acordo com o Acordo do Usuário da empresa. `,
    user_agreement_2: `2. É de responsabilidade do membro garantir a confidencialidade de sua conta e informações de login. Quaisquer apostas online feitas com o seu número de conta e senha de membro serão consideradas válidas. Por favor, altere sua senha de tempos em tempos. A empresa não se responsabiliza por qualquer compensação de apostas feitas com conta e senha roubadas. `,
    user_agreement_3: `3. A empresa reserva-se o direito de alterar este acordo ou as regras do jogo ou as regras de confidencialidade de tempos em tempos. Os termos modificados entrarão em vigor na data especificada após a ocorrência da alteração, e o direito de tomar decisões finais sobre todas as disputas é reservado à empresa. `,
    user_agreement_4: `4. Os usuários devem ser maiores de idade de acordo com as leis do país de residência para usar cassino ou aplicativo online. As apostas online que não tenham sido submetidas com sucesso serão consideradas nulas.`,
    user_agreement_5: `5. Quando um jogador é desconectado automaticamente ou forçosamente do jogo antes que o resultado do jogo seja divulgado, isso não afetará o resultado do jogo.`,
    Messages: "Mensagens",
    Account: "Conta",
    Main_Wallet: "Carteira central",
    To_achieve_next_level: "Para alcançar o próximo nível",
    Betting_still_lacks: "Apostar ainda falta",
    Upgrade_Needs_Wager: "Aposta necessária",
    Account_Details: "Detalles da Conta",
    Bet_Records: "Recordes de Apostas",
    Report: "Relatórios",
    Reports: "Relatório Pessoal",
    Withdrawal_Management: "Configura de saque",
    Withdrawal_Method: "Método de Retirada",
    million_monthly: "milhões por mês",
    Security_Center: "Centro de Segurança",
    Language: "Idioma",
    Feedback_to_get_rewards: "Bônus de Sugestão",
    Quit: "Sair",
    Login_Now: "Login Agora",
    First_deposit_upon_registration: "Registar o primeiro depósito",
    Bonus: "Bônus",
    Collected: "Completado",
    Task_time: "Hora da tarefa",
    bonus_1: "Missões de longo prazo (cada nova conta só pode participar uma vez)",
    bonus_2: "II.Condições da tarefa:",
    bonus_2_content: "Complete as definições relevantes e a ligação de segurança da nossa conta",
    bonus_3: "III.Conteúdo da tarefa:",
    bonus_3_content: `1.Todas as Membros recém-registrados completar as tarefas acima mencionadas e receber um determinado montante de bônus após a conclusão da tarefa, quanto maior for a dificuldade, maior será a recompensa.<br>2.Visto que cada conta é completamente anónima, se a mesma for roubada, não será possível recuperar a perda de fundos resultante do roubo. Por conseguinte, impusemos um processo de verificação das contas em duas etapas, em particular a inclusão do e-mail de pagamento para validar as ações realizadas pelo proprietário da conta e garantir a segurança da sua conta.<br>3.Receber diretamente quando as condições são cumpridas. Pode receber diretamente em qualquer um dos pontos das iOS,Android,H5,PC, os fundos são considerados nulos quando expirados (considerados voluntariamente perdidos quando não recolhidos pelo utilizador).<br>4.Devido ao elevado bónus atribuído por esta missão, o bônus oferecido necessita de 2 X corrente (ou seja, auditoria, participações ou apostas válidas) para poder receber o dinheiro. As participações não estão limitadas a plataformas específicas.<br>5.Esta missões tem utilização limitada ao proprietário da conta para uma jogabilidade normal. O recurso a esquemas de aluguer, a utilização de simuladores (programas de burla), robots, apostas intencionais com contas diferentes, configurações intencionais, concertação, associação, acordo, utilização de lacunas, controlo de grupo ou outros meios técnicos de participação são estritamente proibidos, caso contrário, as recompensas serão confiscadas, congeladas ou deduzidas da recompensa, ou as contas poderão mesmo ser postas na lista negra.<br>6.Para evitar diferenças no entendimento do texto, a plataforma manterá a interpretação final deste evento.`,
    Accumulated_bets_made: "Apostas acumuladas feitas",
    Go: "Prosseguir",
    Deposit_Records: "Registro de recarga",
    Online_Deposit: "Depósito on-line",
    Deposit_Amount: "Valor do Depósito",
    Bonus_event_explanation: "Detalhes adicionais do evento de bónus",

    Deposit_Now: "Recarregue Agora",
    The_first_deposit_to_receive: "O primeiro depósito a receber",
    Total_Deposit: "Montante total de recarga",
    Deposit_Details: "Deposit Details",
    Today: "Hoje",
    Yesterdaty: "Ontem",
    Days: "Dias",
    Day: "Dia",
    DAY: "DIA",
    All: "Tudo",
    Total_Withdrawal: "Total de saques",
    Total_Withdraw: "Retiradas Acumuladas",
    Accumulated_Claimed_Benefits: "Benefícios reivindicados acumulados",
    Transaction_Type: "Tipo de transação",
    Action: "Operação",
    Deposit_Method: "Método de depósito",
    Deposit_channels: "Canais de depósito",
    Time_Created: "Hora de Criação",
    Order_No: "Nº do Pedido",
    Newbie_Bonus: "Bônus para novatos",
    Daily_Mission: "Missão Semanal",
    Claim_All: "Coletar Tudo",
    History: "Reg de Coletas",
    Claim: "Pendente",
    Claim_1: "Receber",
    Claimed: "Recolhido:",
    Next: "Seguintes",
    No_Records: "Sem Registros",
    Level: "Nível",
    Valid_Bets: "Apostas Válidas",
    First_Deposit: "Depósito inicial",
    Online_login_and_counts: 'Atual',
    Online_login_and_counts_pc: "Início de sessão e contagem online",
    Commission: "Comissões",
    Agent_Network: "Tutorial de promoção",
    Referral_Link: "Meus Indicados",
    Performance: "Minha Comissão",
    Subordinate_s_Wagers: "Finanças diretas",
    All_data: "Todos os dados",
    Subordinate_stats: "Dados diretos",
    Agent_Bonus_Rate: "Taxa de Comissão",
    Collectable: "Coletável",
    Agent_Tier: "Nível do Agente",
    Promotion_conditions: "Condições da promoção (resultados acumulados)",
    Total_Pending_Audit: "Tipo de Transação",
    Audit_Status: "Status da auditoria",
    Funds_Change: "Valor da transação",
    Number_of_audits: "Vezes auditado",
    Total_Audit_Required: "Total para Auditoria",
    Pending_audit: "Auditoria pendente",
    Audited: "Auditado",
    Limited_to_the_platform: "Limitado à plataforma",
    Game_Limited: "Jogo restrito",
    Total_Bet_Lucky_Spin: "Bônus para convidar amigos",
    My_Link: "Link exclusivo",
    Quick_Share: "Partilha rápida",
    valid_s: "Pessoas de nível inferior eficazes ",
    people: " pessoas",
    Details: "Detalhes",
    box_tip: "Qual é o número de jogadores efetivamente promovidos? (cumprir todas as condições indicadas abaixo)",
    the_s: "O subordinado acumulou recargas",
    the_turnover: "O subordinado acumulou apostas",
    Or_the_above: "Ou o acima mencionado",
    Create: "Criar",
    Mine: "meu",
    Feedback_Content: "Conteúdo do comentário",
    Suggestions_for_revision: "Relate os problemas e farei melhorias",
    Attachment: "Anexo",
    Easier_to_be_adopted: "Mais fácil de adotar",
    Reupload: "Carregar Novamente",
    upload_tip: "É suportado o envio de imagens e vídeos (tamanho não superior a 50MB)",
    Reward_Rules: "Regras para recompensas",
    Reward_Rules_content: "Estabelecemos uma grande recompensa para recolher sugestões que permitam otimizar o sistema e o seu funcionamento, a fim de lhe proporcionar uma melhor experiência! Caso sejam adotadas, serão concedidas recompensas.",
    Submit_feedback: "Submeter comentário",
    No_Messages: "Sem Mensagem",
    online_tip: "Serviço ao cliente disponível para ajudar a solucionar problemas e responder a dúvidas apresentadas.",
    plat_sup: "atendimento ao Cliente",
    News: "Notícia",
    Notification: "Notificação",
    Marquee: "Painel Rolante",

    daily_i_1: "Sessão iniciada ",
    daily_i_2: " dias consecutivos",
    Required_deposit: "É necessário para carregar ",
    Required_bets: "Apostas: ",
    daily_rule_title_1: "I.Condições do Evento:",
    daily_rule_content_1: "Depósito + participação total (reinicialização após 7 dias seguidos)",
    daily_rule_title_2: "II.Instruções Do Evento:",
    daily_rule_content_2: `1.Apenas se iniciar sessão e cumprir as condições de recarga e de apostas diárias, receberá um bónus fixo ou bónus misterioso, até 18;<br>
  2.Este evento é uma atividade de check-in consecutivo (se interrompida, a contagem será reiniciada e começará a partir do primeiro dia);<br>
  3.As recompensas só podem ser retiradas manualmente no iOS、Android、H5、PC;<br>
  4.A atribuição de bônus desta atividade (excluindo o capital) requer 1 X rollover (isto é, auditoria, participação ou aposta válida) para levantar o dinheiro. Participações não limitadas por jogo ou plataforma:<br>
  5.Este evento é limitado a operações normais realizadas pelo titular da conta. É proibido alugar, usar plug-ins externos, robôs, apostar em contas diferentes, brushing mútuo, arbitragem, interface, protocolo, exploração de vulnerabilidades, controle de grupo ou outros meios técnicos para participar. Caso contrário, as recompensas serão canceladas ou deduzidas, a conta será congelada ou até mesmo adicionada à lista negra;<br>
  6.Para evitar diferenças no entendimento do texto, a plataforma manterá a interpretação final deste evento.<br>`,
    Back: "Retornar",

    Hot: "Populares",
    Recent: "Recente",

    Distributed: "Distribuído",
    Deposited: "Existir",
    Transfer_in: "Depósito",
    Transfer_out: "Retirar",
    Settlement_period: "Ciclo de assentamento",
    hours: "hora",
    Total_claimed: "Coleção cumulativa",
    Interest_ceiling: "Coletar",
    No_restrictions: "Ilimitado",
    Total_Earnings: "Renda Total",
    Record_details: "Detalhes do registro",
    Interest_rules: "Regras de juros",
    Interest_tips: "Taxa de juros anual 15%",
    Time: "Horas",
    Type: "Tipos",
    Amount: "Quantia",
    Rebate_Records: `Reg <br>de Coletas`,
    Rebate_rate: "Taxa de Cashback",
    Receive_amount: "Receba valor",
    Rebateable: "Coletável",
    to_level: "Para o próximo nível",
    bsl: "Aposta válida ainda é necessária  ",
    VIP_Level_List: "Lista de níveis VIP",
    Promotion_Bonus: "Bônus de aumento de nível",
    Weekly_Bonus: "Bônus Semanal",
    Monthly_Bonus: "Bônus Mensal",
    Tips: "Termos de uso",
    vip_rule_title: "Instruções sobre regras VIP",
    tips_content: `Ou seja, a promoção para o próximo nível precisa se basear na aposta efetiva acumulada original e, em seguida, na quantidade de apostas efetivas, a promoção de apostas efetivas VIP1 requer 1.000, e as apostas válidas VIP2 requerem 2000, então os membros precisam acumular -se acumulados Apostas efetivas 1000+2000 = 3000 para avançar para o avanço VIP2, assim por diante.\n`,
    vip_rule: `1.Padrão de promoção: atenda aos requisitos da promoção VIP (ou seja, a recarga ou apostas eficazes podem atender às condições), você pode avançar para o nível VIP correspondente e obter o bônus de promoção correspondente.O bônus pode ser recebido de tempos em tempos;\r\n
  2.Salário diário: Se a recarga diária e as apostas válidas atenderem aos requisitos salariais diários do nível atual, você poderá obter o bônus salarial diário correspondente. Se você avançar para vários níveis consecutivos, só poderá obter o bônus salarial diário do atual nível.O bônus pode ser recebido de tempos em tempos;\r\n
  3.Salário Semanal: Se a recarga semanal e as apostas válidas atenderem ao nível atual de requisitos salariais semanais, você poderá obter o bônus salarial semanal correspondente. Se você avançar para vários níveis consecutivos, poderá obter apenas o nível atual de bônus salarial semanal.O bônus pode ser recebido de tempos em tempos;\r\n
  4.Lulu mensal: recarga mensal e apostas efetivas para atender ao nível atual do Lulu mensal, e você pode obter o bônus de prêmio mensal correspondente.O bônus pode ser recebido de tempos em tempos;\r\n
  5.Tempo de vencimento da recompensa: reservas de longo prazo para bônus obtidos, você precisa receber manualmente;\r\n
  6.Instruções para auditoria: o bônus VIP oferecido pode ser levantado apenas após o cumprimento do requisito de rollover 1x (ou seja, auditoria, apostas ou apostas válidas), independentemente da plataforma em que joga;\r\n
  7.Declarações: Esta função está limitada às operações normais dos titulares de contas. É proibido alugar contas, efetuar apostas sem risco (apostas com contas diferentes, swiping mútuo e swiping de odds baixas), arbitragem viciosa, utilizar plug-ins, robôs, exploração de acordos, lacunas, interfaces, controlo de grupo ou outros meios técnicos de participação; caso contrário, uma vez provado, a plataforma tem o direito de proibir os membros de iniciar sessão, suspender a utilização do nosso website, e confiscar o bônus e os ganhos indevidos, sem qualquer aviso especial;\r\n
  8.Instruções: Ao reclamar o bônus VIP, considera-se que os membros aceitam e cumprem as regras correspondentes. A fim de evitar diferenças na compreensão do texto, a plataforma reserva o direito final de interpretar esta atividade.`,
    Misson: "Missão",
    Set_Up: "Configurar",
    Link: "Ir para Vinculação",
    Please_link_your_mobile_phone: "Associe o seu telemóvel",
    Please_link_your_mail: "Vincule o seu e-mail",
    birth_tip: "Selecione a data de nascimento (depois de definida, não pode ser modificada)",
    pltwaf: "Vincule primeiro a conta de levantamento",
    ped: "Introduza 6-40 números",
    plw: "Entre no WhatsApp",
    pefa: "Por favor insira suaconta do facebook",
    pet: "Por favor, informe seu nome de usúario no Telegram",
    eut: "Introduza 5-40 ",
    Year: "Ano",
    Save: "Salvar",
    Male: "Masculino",
    Female: "Masculino",
    Statement: "Detalhes da     Conta",
    Cumulative_bet_amount: " Número total de apostas efetuadas",
    Total_Valid_Bets: "Total de Apostas Válidas",
    Total: "Vitórias/Perdas Total",
    nob: "Apostas/",
    ba: "Valor/",
    Ganhos: "Ganhos",
    Verify_Login_Password: "Verifica a senha",
    you_tip: "O número de telemóvel pode ser vinculado após verificação da palavra-passe de início de sessão",
    Other_Verification_Methods: "Outros Métodos",
    The_above_method_is_not_available: "O método acima não está disponível? ",
    Contact_Us: "Contacte-nos",
    Enter_Password: "Inserir Senha",
    Mobile_Phone: "Nº de Telefone",
    phone: "Digite o Número do Celular",
    Player_ID: "Nome de Usuário",
    Unlinked: "Desvincular",
    Email: "Endereço de E-mail",
    Login_Password: "Palavra-passe de início de sessão",
    Security_Question: "Pergunta",
    Link_Third_party_Login: "Enlazar inicio de sesión de terceros",
    Unset: "Sin definir",
    set_title: "Es tu primera retirada, necesitas establecer una contraseña de retiro primero",
    set_up: "Configurar contraseña de retiro",
    New_Withdrawal: "Nueva contraseña de retiro",
    Old_Withdrawal: "Contraseña de retiro anterior",
    password_note: `Nota: Tu contraseña de retiro protege la seguridad de tus fondos. Es muy importante y 
    solo debe ser conocida por ti para evitar cualquier pérdida financiera.`,
    confirm: "confirmar",
    Confirm: "Confirmar",
    Current_Lucky_Value: "Valor afortunado actual",
    you_still_need: "Todavía necesitas apostar",
    to_get: "para obtener",
    Lucky_Points: "Puntos afortunados",
    Instant: "Dibujo instantáneo<br>",
    Luck_Value: "Valor de suerte",
    Silver: "Plata",
    Golden: "Oro",
    Diamond: "Diamante",
    Reward_announcement: "Anuncio de recompensas",
    My_Records: 'Mis registros',
    won_at: "ganó en",
    returntable_rule_content: "Apuestas válidas acumuladas (es decir, apuestas o auditorías de plataformas específicas)",
    Designated_platform: "Plataforma designada",
    return_table_content_2: `1. Durante el período del evento, cada apuesta válida gana automáticamente un punto afortunado. De 1000 a 10000 puntos afortunados permiten un sorteo, con una recompensa máxima de 9999.<br>
    2. Los puntos afortunados obtenidos hoy y no usados al día siguiente expirarán y se restablecerán a cero;<br>
    3. Las recompensas solo pueden reclamarse manualmente en iOS, Android, H5, PC<br>
    4. El bono otorgado en este evento (excluyendo el capital) requiere 1 vez de apuesta (es decir, verificación, 
    apuesta o apuesta válida) para retirar, la apuesta no está limitada a una plataforma de juego;<br>
    5. Este evento solo es para los propietarios de cuentas normales que operan manualmente, alquilar, usar software de trampa,
    bots, apuestas entre diferentes cuentas, manipulación mutua, arbitraje, APIs, protocolos, explotación de vulnerabilidades, control grupal u otros medios técnicos están prohibidos, de lo contrario las recompensas pueden ser
    canceladas o deducidas, o las cuentas pueden ser congeladas e incluso incluidas en una lista negra;<br>
    6. Para evitar diferencias en la comprensión del texto, la plataforma se reserva la interpretación final
    de este evento.`,
    twde: "Apuestas totales durante el período del evento",
    Used_Lucky_Value: "Valor afortunado usado",
    Expired_Lucky_Value: "Valor afortunado expirado",
    Available_Lucky_Value: "Valor afortunado disponible",
    Claim_Successfully: "Reclamar exitosamente",
    Total_Account_Balance: "Saldo total de la cuenta",
    One_click_transfer: "Transferencia de un clic",
    Live: "En vivo",
    Fishing: "Pesca",
    Sports: "Deportes",
    Cards: "Cartas",
    Lottery: "Lotería",
    Blockchain: "Juegos de blockchain",
    Slots: "Tragamonedas",
    All_Type: "Todos los tipos",
    ALL_Platform: "Todas las plataformas",
    Platform: "Plataforma",
    Total_Commission: "Comisión total",
    Referral_information: "Información de referencia",
    More: "Más",
    Commission_Collected: "Comisión recolectada",
    Last_Commission: "Última comisión",
    Total_Members: "Miembros totales",
    Total_angent: "Total",
    Direct_Subordinates: "Subordinados directos",
    Others: "Otros",
    Total_Perf: "Rendimiento total",
    Sub_Perf: "Rendimiento del subordinado",
    Others_Perf: "Rendimiento de otros",
    Subordinate_Wagers: "Apuestas del subordinado",
    Total_valid_bets: "Apuestas válidas totales",
    total_no: "Número total de apuestas",
    Total_angent_wl: "Ganancia/perdida total",
    Withdrawal_Account: "Cuenta de retiro",
    Hide: "Ocultar",
    Show: "Mostrar",
    Add_New_Bank_Account: "Agregar nueva cuenta bancaria",
    Enter_PIN: "Introducir PIN",
    for_you_account: "Para tu seguridad, por favor introduce la contraseña de retiro",
    pix_tip: "La cuenta PIX no puede estar vacía",
    digits_11: "Por favor ingresa 11 dígitos",
    digits_6: "Por favor ingresa 6 dígitos",
    Set_as_Default: "Establecer como predeterminado",
    Add_withdrawal_account: "Agregar cuenta de retiro",
    add_account_tip: "Por favor selecciona una cuenta de retiro primero",
    with_tip_1: "La cantidad restante requerida para retirar es",
    with_tip_2: " ",
    Min: 'Mínimo',
    Max: 'Máximo',
    Withdrawal_Amount_cannot_be_empty: 'El monto de retiro no puede estar vacío',
    withdraw_err: "Retiro no disponible hasta que se complete la auditoría",
    wp_can_be_empty: "La contraseña de retiro no puede estar vacía",
    Can_be_collected_today: "Puede ser recolectado hoy",
    Fee: "Tarifa",
    Add: "Agregar",
    All_status: "Todos los estados",
    Not_settled: 'No resuelto',
    Settled: 'Resuelto',
    Order_canceled: "Orden cancelada",
    Confirm_New_Withdrawal_Password: "Confirmar nueva contraseña de retiro",
    Search: "Buscar",
    Search_Games: "Buscar juegos",
    tab_Search: "Buscar",
    Favorite: "Favorito",
    Reports_tab: "Informe",
    em_claim: "Actualmente no hay recompensas disponibles para reclamar",
    Start_Date: "Fecha de inicio",
    End_Date: "Fecha de fin",
    Cancel: "Cancelar",
    My_ID: "Mi ID",
    Contribution_Commission: "Comisión de contribución",
    total_first: "Jugadores de primer depósito totales",
    Other_first_deposit: "Otro primer depósito",
    Other_deposit: "Otro depósito",
    Direct_first_deposit: "Primer depósito directo",
    Subordinate_Deposits: "Depósitos del subordinado",
    Subordinate: "Subordinado",
    Full_Screen: "Pantalla completa",
    Refresh: "Actualizar",
    Yes: "Sí",
    No: "No",
    Settlement_date: "Fecha de liquidación",
    Contributors: "Contribuyentes",
    Joining_time: "Tiempo de incorporación",
    Member_ID: "ID del miembro",
    Statistical_date: "Fecha estadística",
    Number_of_bets: "Número de apuestas",

    Total_W_L: "Ganancia/perdida total",
    bounusTip: "Los miembros de la línea inferior solo contribuirán con devoluciones si sus depósitos ≥0 y han realizado apuestas válidas ≥0 durante el período de liquidación.",
    Type_Details: "Detalles del tipo",
    Bet_ID: "ID de apuesta",
    Bet_amount: "Cantidad de apuesta",
    In_game: "Uso en juego",
    page: 'página',
    Page: 'Página',
    entries: 'entradas',
    page_total_1: "Total de",
    page_total_2: "entradas",
    more_1: "Actualmente mostrando",
    more_2: "Juegos populares fuera de",
    more_3: "",
    Load_More: "Cargar más",
    Reminder: "Recordatorio",
    Log_out_from_account: "Cerrar sesión de la cuenta",
    Cashback_Exclusivo: "Reembolso exclusivo",
    privileges_Exclusivo: "Privilegios VIP",
    login_password_tip: 'Modificar la contraseña de inicio de sesión después de verificar la contraseña de inicio de sesión',
    Confirm_the_new_password: "Confirmar la nueva contraseña",
    Daily_loss_rescue_fund: "Fondo de rescate diario",
    Loss_amount: "Cantidad de pérdida",
    Extra_Rewards: "Recompensas extras",
    Yesterday_s_losses: "Pérdidas de ayer",
    Today_s_Rescue_Bonus: "Bono de rescate de hoy",

    pleaseOpenYour:'Abra seu aplicativo de pagamento e digitalize ou copie e cole o código QR abaixo para concluir sua compra;',
    This_QR_code:'Este código QR só pode ser pago uma vez. Se precisar pagar novamente, volte e recarregue;',
    After_the_payment:'Após o pagamento ser bem-sucedido, você pode retornar ao lobby do jogo e aguardar a adição de pontos!',
    Effective_time:'Tempo efetivo',
    Copy_QR_code:'Copiar código QR',
    QR_code_address:'Endereço do código QR',
    Order_Status:'Status do pedido',
    To_be_paid:'Registros de Auditoria',
    Creation_Time:'Hora de Criação',
    Order_Number:'Número do Pedido',
    Merchant_order_number:'Número do pedido do comerciante',
    success:'sucesso',
    failure:'Falha',
};