<template>
  <span class='pg-check' :class="{ checked: value }" @click.stop="$emit('input', !value)">
    <span class="checkbox-inner" :class="{ transparent }"></span>
  </span>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    transparent: {
      type: Boolean
    }
  },
  data() {
    return {

    }
  },
  methods: {},
}
</script>

<style lang='scss' scoped>
.pg-check {
  vertical-align: middle;
  white-space: nowrap;
  line-height: 1;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 0;
  position: relative;
  box-sizing: border-box;
  color: rgba(0, 0, 0, .65);
  cursor: pointer;
  display: inline-block;
  font-size: 14px;

  .check-input {
    bottom: 0;
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  .checkbox-inner {
    display: flex;
    justify-content: center;
    border-radius: 0.06rem;
    font-size: .15rem;
    height: 0.32rem;
    width: 0.32rem;
    background: var(--theme-bg-color);
    border: 1px solid var(--theme-color-line);

    &::after {
      content: "";
      bottom: 0px;
      display: inline-flex;
      mask-size: 0.248rem;
      height: 2em;
      left: 0px;
      right: 0px;
      top: 0px;
      bottom: 0;
      object-fit: cover;
      position: absolute;
      width: 2em;
      mask-repeat: no-repeat;
      mask-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDIwIDE1Ij4NCiAgPHBhdGggaWQ9ImNvbW1faWNvbl9nb3UiIGQ9Ik0xMjM0LjIyLTU5Ny42MTVsLTYuNTg0LTUuNjI4YTEuNTI0LDEuNTI0LDAsMCwxLS4xNDktMi4xNDEsMS41LDEuNSwwLDAsMSwyLjEyOC0uMTVsNS40MzgsNC42NDgsOS40MTctMTAuODM0YTEuNSwxLjUsMCwwLDEsMi4xMjgtLjE1LDEuNTI1LDEuNTI1LDAsMCwxLC4xNDksMi4xNDFsLTEwLjQsMTEuOTY0YTEuNSwxLjUsMCwwLDEtMS4xMzkuNTIyQTEuNDk0LDEuNDk0LDAsMCwxLDEyMzQuMjItNTk3LjYxNVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMjI3LjExNyA2MTIuMjQyKSIgZmlsbD0iIzA0YmUwMiIvPg0KPC9zdmc+DQo=);
      margin: auto;
      mask-position: 50% 50%;
      background-color: var(--theme-bg-color);
    }

    &.transparent {
      background-color: rgba($color: #000000, $alpha: .2);

      &::after {
        opacity: 0;
      }
    }
  }

  &.checked {
    .checkbox-inner {
      background: var(--theme-secondary-color-success);

      &::after {
        opacity: 1;
        background-color: var(--theme-primary-color);
      }
    }
  }
}
</style>