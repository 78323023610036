<template>
  <div class="pg-container" v-loading="loading">
    <table class="pg-table" cellspacing="1" cellpadding="1">
      <colgroup>
        <col v-for="(item, index) in tableConfig" :key="index" :style="[{ width: item.width || 'auto' }]">
      </colgroup>
      <thead class="table-thead">
        <tr>
          <th v-for="(item, index) in tableConfig" :key="index" :style="{ textAlign: item.align || 'left' }">
            <div class="table-column">
              <div class="th-cell" :class="item.sortable ? 'sortable' : ''">

                <span class="cell-title">
                  <slot v-if="item.headeSlot" :name="item.headeSlot"></slot>
                  {{ item.label }}
                </span>
                <span v-if="item.sortable" class="caret">
                  <div class="care-warp">
                    <i class="caret-up" :class="{ active: item.sort == 1 }" @click="setSort(item, 1)">
                      <Icon type="caret-up"></Icon>
                    </i>
                    <i class="caret-down" :class="{ active: item.sort == 2 }" @click="setSort(item, 2)">
                      <Icon type="caret-down"></Icon>
                    </i>
                  </div>
                </span>
              </div>
            </div>
          </th>
        </tr>
      </thead>
    </table>

    <div v-if="tableData.length" :style="[{ height, 'scrollY': height ? 'scroll' : 'hidden' }]">
      <scrollbar>
        <table class="pg-table" cellspacing="1" cellpadding="1">
          <colgroup>
            <col v-for="(item, index) in tableConfig" :key="index" :style="[{ width: item.width || 'auto' }]">
          </colgroup>
          <tbody class="table-tbody">
            <tr v-for="(item, index) in tableData" :key="index">
              <td v-for="(configItem, configIndex) in tableConfig" :key="configIndex"
                :style="{ textAlign: configItem.align || 'left' }">
                <div class="cell">
                  <slot v-if="configItem.type === 'slot'" :name="configItem.prop" :data="item" :index="index" />
                  <span v-else>{{ item[configItem.prop] }}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </scrollbar>
    </div>
    <div v-if="tableData.length == 0" class="table-empty-wrap">
      <empty />
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      tableConfig: [],
      tableData: [],
    }
  },
  props: {
    config: {
      type: Array,
      default() {
        return []
      }
    },
    data: {
      type: Array,
      default() {
        return []
      }
    },
    loading: {
      type: Boolean,
      default() {
        return false
      }
    },
    height: {
      type: String,
      default: () => 'auto'
    }
  },
  watch: {
    config: {
      handler(val) {
        if (val) {
          this.tableConfig = val;
        }
      },
      immediate: true
    },
    data: {
      handler(val) {
        if (val) {
          this.tableData = val;
        }
      },
      immediate: true
    }
  },
  methods: {

    setSort(item, sort) {
      let tempData = [...this.data]
      this.tableConfig.forEach(o => {
        if (JSON.stringify(item) !== JSON.stringify(o)) {
          this.$set(o, 'sort', -1)
        }
      })
      if (item.sort == sort) {
        this.$set(item, 'sort', -1)
      } else {
        this.$set(item, 'sort', sort)
      }
      if (item.sortFunc) {
        item.sortFunc(item, sort)
        return
      }


      if (item.sort === -1) {
        this.tableData = tempData
      } else {
        this.tableData = tempData.sort((a, b) => {
          if (sort === 1) {
            return a[item.prop] - b[item.prop]
          } else {
            return b[item.prop] - a[item.prop]
          }
        })
      }
    }
  },
}
</script>

<style lang='scss' scoped>
.pg-container {
  .pg-table {
    border-collapse: separate;
    border-radius: 4px 4px 0 0;
    border-spacing: 0;
    text-align: left;
    width: 100%;
    table-layout: fixed;

    .table-thead {
      height: 0.8rem;
      border-radius: 0.06rem;
      color: var(--theme-text-color-lighten);

      th {
        font-size: .22rem;
        padding: 0 0.13rem;
        font-weight: 400;
        max-width: 100%;
        white-space: nowrap;
        color: var(--theme-text-color-darken);
        border-top: 0.01rem solid var(--theme-color-line);
        border-bottom: 0.01rem solid var(--theme-color-line);

        .table-column {
          display: inline-block;

          .th-cell {
            max-width: 100%;
            display: inline-block;
            white-space: nowrap;

            .cell-title {
              word-break: break-word;
              white-space: normal;
              display: table-cell;
              vertical-align: middle;
            }

            .caret {
              display: table-cell;
              position: relative;
              cursor: pointer;
              text-align: center;
              vertical-align: middle;

              .care-warp {
                display: flex;
                flex-direction: column;
                margin-left: .57142857em;

                i {
                  height: 0.9em;
                  color: var(--theme-text-color-lighten);
                  font-size: .14rem;
                }

                .active {
                  color: var(--theme-primary-color);
                }
              }
            }
          }
        }



        .sortable {
          display: table;
        }
      }

      tr:first-child {
        th {
          &:first-child {
            border-bottom-left-radius: 0.1rem;
            border-top-left-radius: 0.1rem;
            border-left: 0.01rem solid var(--theme-color-line);
          }

          &:last-child {
            border-bottom-right-radius: 0.1rem;
            border-top-right-radius: 0.1rem;
            border-right: 0.01rem solid var(--theme-color-line);
          }
        }

      }
    }

    .table-tbody {
      tr {
        background: var(--theme-main-bg-color);

        &:nth-of-type(2n) {
          background: var(--theme-bg-color);
        }

        td {
          height: auto;
          padding: 0 0.13rem;
          font-size: .2rem;
          color: var(--theme-text-color);
        }

      }
    }


  }

  .table-empty-wrap {
    height: 5rem;
  }
}

.pc-page{
  .pg-container .pg-table {
    .table-thead {
      height: 0.7rem;

      th {
        font-size: .2rem;
      }
    }

    .table-tbody tr td .cell {
      padding: 0.2rem 0;
    }
  }
}
</style>