<template>
  <div class="avatar">
    <img class="img-avatar" :src="userInfo.avatar" alt="">
    <div class="level" @click="$router.push('/offers?current=2')">
      <img class="level-icon" src="@/assets/images/img_vip.png" />
      <span class="level-text" :data-text="userInfo.level">{{ userInfo.level }}</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo
    })
  },
  data() {
    return {

    }
  },
  methods: {},
}
</script>

<style lang='scss' scoped>
.avatar {
  width: 1.5rem;
  border-radius: 0.1rem;
  height: 1.5rem;
  position: relative;

  .img-avatar {
    width: 100%;
    height: 100%;
    display: block;
  }

  .level {
    bottom: 0;
    position: absolute;
    right: 0;
    height: .35rem;
    max-width: 0.88rem;
    min-width: 0.83rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.035rem;
    background: rgb(36, 178, 153);

    .level-icon {
      width: 0.368rem;
      height: 0.172rem;
      display: block;
    }

    .level-text {
      font-size: 0.2rem;
      margin-left: 0.01rem;
    }

  }
}
.pc-page{
  .avatar .level {
    height: 0.12rem;
    max-width: 0.33rem;
    min-width: 0.29rem;
    display: flex;
    align-items: center;

    .level-icon {
      min-width: 0.163rem;
      width: 0.163rem;
      height: auto;
    }

    .level-text {
      font-size: .1rem;
    }
  }
}
</style>