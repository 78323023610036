<template>
  <div>
    <audio ref="audio" @loadedmetadata="onLoadedMetadata" :src="currentPlayMusicInfo.url"
      style="display: none;"></audio>
    <pg-poup v-model="visible" :title="$t('Music')">
      <div class="music-container">
        <div class="volume-card">
          <PgDragProgress ref="volume" type="volume" :duration="1" :currentTime="currentVolume" @change="changeVolume"/>
        </div>
        <div class="music-player-box">
          <div class="music-player-name">{{ currentPlayMusicInfo.name }}</div>
          <div class="music-progress">
            <PgDragProgress ref="progress" :duration="duration" :currentTime="currentTime"
              @change="handleChangeAudioTime" />
          </div>
          <div class="action-area">
            <div class="loop" @click="loopClick">
              <Icon class="loop-icon" :type="loopList[loopIndex].icon" />
              <span class="loop-text">{{ $t(loopList[loopIndex].label) }}</span>
            </div>
            <div class="main-action">
              <div class="icon-wrap" @click="changePlayIndex(-1)">
                <Icon type="last" />
              </div>

              <div class="icon-wrap" @click="playOrPause">
                <i class="loading-icon" v-if="musicLoading">
                  <Icon type="loading" />
                </i>
                <Icon v-else :type="isPause ? 'play' : 'pause'" />
              </div>
              <div class="icon-wrap" @click="changePlayIndex(1)">
                <Icon type="next" />
              </div>
            </div>
            <div class="action-list">
              <div class="download-num">{{ downloadIdArr.length }}</div>
              <div class="downliad-text">{{ $t('Downloaded') }}</div>
            </div>
          </div>
        </div>
        <div class="music-list-container">
          <div class="tab-list">
            <div class="tab-item" :class="[{ active: currentTab == 1 ? 'active' : '' }]" @click="currentTab = 1">
              <span class="tab-item_text">{{ $t('System_Music') }}</span>
            </div>
            <div class="tab-item" :class="[{ active: currentTab == 2 ? 'active' : '' }]" @click="currentTab = 2">
              <span class="tab-item_text">{{ $t('My_music') }}</span>
            </div>
          </div>
          <div class="scroll-container">
            <ul class="music-list" v-show="currentTab == 1">
              <li v-for="(item, index) in musicList" @click="playMusic(item)" :key="index"
                :class="[{ active: item.id == currentPlayId }]">
                <div class="music-left">
                  <Icon class="music-icon" type="music" v-if="item.id == currentPlayId" />
                  <span v-else>{{ index + 1 }}</span>
                  <span class="name">{{ item.name }}</span>
                </div>
                <div class="music-right">
                  <i class="loading-icon" v-if="item.id == currentPlayId && musicLoading">
                    <Icon type="loading" />
                  </i>
                  <Icon v-else-if="downloadIdArr.includes(item.id)" class="checked" type="true" />
                  <Icon v-else class="download" type="download" />
                </div>
              </li>
            </ul>
            <ul class="music-list" v-show="currentTab == 2">
              <li v-for="(item, index) in myList" :key="index" :class="[{ active: item.id == currentPlayId }]">
                <div class="music-left" @click="playMusic(item)">
                  <Icon class="music-icon" type="music" v-if="item.id == currentPlayId" />
                  <span v-else>{{ index + 1 }}</span>
                  <span class="name">{{ item.name }}</span>
                </div>
                <div class="music-right">
                  <i class="loading-icon" v-if="item.id == currentPlayId && musicLoading">
                    <Icon type="loading" />
                  </i>
                  <Icon v-else-if="downloadIdArr.includes(item.id)" class="checked" type="true" />
                  <Icon v-else class="download" type="download" />
                  <div @click="removeMusic(item)" v-if="item.id !== currentPlayId">
                    <Icon type="delete" class="delete" v-if="myList.length > 0" />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </pg-poup>
  </div>

</template>

<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      visible: false,
      duration: 0,
      currentTime: 0,
      intervalId: null,
      currentTab: 1,
      isPlayFlag: false,
      musicLoading: false,
      currentVolume:0.5,
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.progress.init()
          this.$refs.volume.init()
        })
      }
    }
  },
  computed: {
    ...mapState({
      musicList: state => state.music.musicList,
      currentPlayMusicInfo: state => state.music.currentPlayMusicInfo,
      loopList: state => state.music.loopList,
      loopIndex: state => state.music.loopIndex,
      isPause: state => state.music.isPause,
      downloadIdArr: state => state.music.downloadIdArr,
    }),
    currentPlayId() {
      return this.currentPlayMusicInfo?.id
    },
    myList() {
      return this.musicList.filter((item, index) => {
        if (this.downloadIdArr.includes(item.id)) {
          return true
        }
      })
    }
  },
  mounted() {
    this.$refs.audio.addEventListener('timeupdate', this.updateCurrentTime);
    this.$refs.audio.volume = this.currentVolume
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
    this.$refs.audio.addEventListener('ended', this.onAudioEnded);
    this.$refs.audio.addEventListener('canplaythrough', () => {
      this.musicLoading = false;
    });
  },
  beforeDestroy() {
    this.$refs.audio.removeEventListener('timeupdate', this.updateCurrentTime);
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
    // 清除可能存在的定时器
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  methods: {
    handleChangeAudioTime(newTime) {
      this.$refs.audio.currentTime = newTime;  
    },
    changeVolume(e){
      this.$refs.audio.volume = e;
    },
    removeMusic(item) {
      const index = this.downloadIdArr.findIndex(o => o == item.id);
      this.downloadIdArr.splice(index, 1)
      this.$store.commit('SET_DOWNLOAD_ARR', this.downloadIdArr)
    },
    onAudioEnded() {
      this.$store.commit('SET_IS_PAUSE', true);
      if (this.loopIndex == 0) {
        this.changePlayIndex(1)
      } else if (this.loopIndex == 1) {
        const randomIndex = Math.floor(Math.random() * this.downloadIdArr.length);
        const nextSong = this.musicList[this.downloadIdArr[randomIndex]];
        this.$store.commit('SET_CURRENT_PLAY', this.musicList[randomIndex]);
        this.playMusic(nextSong);
      } else {
        this.$refs.audio.currentTime = 0; // 重置播放时间为0，实现单曲循环播放
        this.$refs.audio.play(); // 继续播放当前歌曲
        this.$store.commit('SET_IS_PAUSE', false);
      }
    },
    changePlayIndex(index) {
      if (this.loopIndex == 1) {
        const randomIndex = Math.floor(Math.random() * this.musicList.length);
        const nextSong = this.musicList[randomIndex];
        this.playMusic(nextSong);
        return
      }
      const currentIndex = this.musicList.findIndex(item => item.id === this.currentPlayId);
      
      let nextIndex = currentIndex + index;
      if (nextIndex > this.musicList.length - 1) {
        nextIndex = 0;
      } else if (nextIndex < 0) {
        nextIndex = this.musicList.length - 1
      }
      this.playMusic(this.musicList[nextIndex])
    },
    playMusic(item) {
      if (item.id === this.currentPlayId) return
      if (!this.downloadIdArr.includes(item.id) && item.id) {
        this.downloadIdArr.push(item.id);
        this.$store.commit('SET_DOWNLOAD_ARR', this.downloadIdArr);
      }
      this.$store.commit('SET_CURRENT_PLAY', item);
      this.$nextTick(() => {
        this.currentTime = 0;
        this.$refs.progress.changeCurrentTime(0);
        this.$refs.audio.addEventListener('playing', () => {
          this.musicLoading = false;
        }, { once: true });
        this.musicLoading = true;

        this.playOrPause();
      })
    },
    updateCurrentTime() {
      this.currentTime = this.$refs.audio.currentTime;
    },
    handleVisibilityChange() {
      const audioElement = this.$refs.audio;
      if (document.visibilityState === 'hidden') {
        audioElement.pause();
        this.$store.commit('SET_IS_PAUSE', true);
      } else if (this.isPlayFlag) {
        audioElement.play();
        this.$store.commit('SET_IS_PAUSE', false);
      }
    },
    show() {
      this.visible = true;
    },
    onLoadedMetadata() {
      this.duration = this.$refs.audio.duration;
    },
    loopClick() {
      let tempLoopIndex = this.loopIndex + 1;
      if (tempLoopIndex == 3) {
        tempLoopIndex = 0
      }
      this.$store.commit('SET_LOOP_INDEX', tempLoopIndex);
    },
    playOrPause() {
      const audioElement = this.$refs.audio;
      if (audioElement.paused || audioElement.currentTime === 0) {
        audioElement.play();
        this.isPlayFlag = true;
        this.$store.commit('SET_IS_PAUSE', false);
      } else {
        audioElement.pause();
        this.isPlayFlag = false;
        this.$store.commit('SET_IS_PAUSE', true);
      }
    }
  },
}
</script>

<style lang='scss' scoped>
.music-container {
  width: 6.9rem;
  padding: 0 .5rem .5rem;
  box-sizing: border-box;

  .volume-card {
    padding: .3rem 0;
    border-radius: .2rem;
    margin-bottom: 0.2rem;
    background-color: var(--theme-main-bg-color);
  }

  .loading-icon {
    width: 0.25rem;
    height: 0.25rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    animation: roateAni .6s linear infinite;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .music-player-box {
    padding: .3rem 0;
    border-radius: .2rem;
    background-color: var(--theme-main-bg-color);

    .music-player-name {
      font-size: .2rem;
      color: #fff;
      width: 2.94rem;
      margin: 0 auto;
      white-space: nowrap;
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
    }

    .music-progress {
      margin-top: 0.4rem;
    }

    .action-area {
      height: .8rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: .2rem;
      position: relative;

      .loop {
        width: 1.2rem;
        text-align: center;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .loop-icon {
          color: var(--theme-primary-color);
          font-size: .3rem;
        }

        .loop-text {
          color: #fff;
          font-size: .22rem;
          margin-top: .04rem;
        }
      }

      .main-action {
        display: flex;
        align-items: center;
        left: 50%;
        top: 0;
        bottom: 0;
        transform: translateX(-50%);
        position: absolute;



        .icon-wrap {
          color: var(--theme-primary-color);
          font-size: .3rem;
          display: inline-block;
          position: relative;
          width: 0.6rem;
          height: 0.6rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-image: url('@/assets/images/main.sprites.png');
          background-position: -0.85rem -4.3rem;
          background-size: 7.072rem 6.5416rem;



          &:nth-of-type(2) {
            width: 0.8rem;
            height: 0.8rem;
            margin: 0 .5rem;
            background-image: url('@/assets/images/main.sprites.png');
            background-position: -0.045rem -2.56rem;
            background-size: 7.072rem 6.5416rem;
          }
        }
      }

      .action-list {
        color: var(--theme-text-color-lighten);
        cursor: default;
        font-size: .16rem;
        text-align: center;
        white-space: pre-wrap;
        width: 1.3rem;

        .download-num {
          font-size: .3rem;
          color: var(--theme-text-color-lighten);
          line-height: .24rem;
        }

        .downliad-text {
          font-size: .22rem;
          line-height: 1.06;
          margin-top: .04rem;
        }
      }
    }
  }

  .music-list-container {
    margin-top: .2rem;
    border-radius: .2rem;
    background-color: var(--theme-main-bg-color);

    .tab-list {
      border-bottom: 1px solid var(--theme-color-line);
      font-size: .24rem;
      height: .8rem;
      display: flex;
      line-height: .8rem;

      .tab-item {
        width: 50%;
        text-align: center;
        color: #fff;

        &.active {
          .tab-item_text {
            position: relative;
            display: inline-block;
            height: 100%;
            color: var(--theme-primary-color);

            &:after {
              content: '';
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
              height: 1px;
              background: var(--theme-ant-primary-color-0);
            }
          }
        }
      }
    }

    .scroll-container {
      height: 5.24rem;
      overflow: hidden;

      .music-list {
        padding: 0 0.2rem;
        height: 100%;
        font-size: .22rem;
        color: #fff;
        overflow-y: auto;

        li {
          font-size: .2rem;
          height: .8rem;
          display: flex;
          align-items: center;
          padding: 0;
          border-bottom: thin solid var(--theme-color-line);

          &.active {
            font-size: .22rem;
            color: var(--theme-primary-color);
          }

          .music-icon {
            cursor: pointer;
            font-size: .3rem;
          }

          .music-left {
            display: flex;
            flex: 1;
            overflow: hidden;
            align-items: center;

            .name {
              white-space: nowrap;
              margin-left: 0.2rem;
              display: inline-block;
              width: 80%;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .music-right {
            color: var(--theme-primary-color);
            cursor: pointer;
            min-width: .5rem;
            display: flex;
            align-items: center;
            justify-content: center;

            .download {
              font-size: .5rem;
            }

            .checked {
              font-size: .3rem;
            }

            .delete {
              font-size: .3rem;
              margin-left: 0.2rem;
              color: var(--theme-secondary-color-error);
            }
          }
        }
      }
    }
  }
}
</style>